import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import moment from "moment";
import {
    Tooltip,
    IconButton,
    Modal,
    Box
} from "@mui/material";
import {
    Edit,
    Tune
} from "@mui/icons-material";
import CustomButton from "../../shared/CustomButton";
import CustomTable from "../../shared/CustomTable";
import GlobalStates from '../../../utils/GlobalStates';
import api from "../../../utils/Endpoints";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import HistoricalData from "./HistoricalData"
import { Link, useNavigate } from "react-router-dom";


export const options = {
    maintainAspectRatio: window.innerWidth > 600 ? true : false,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Comparative Product Report',
        },
    },
};

function Products() {
    const globalStates = useHookState(GlobalStates);

    const [isTableLoading, setIsTableLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [historical, setHistorical] = useState([])
    const [historicalData, setHistoricalData] = useState([])
    const [open, setOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState([])
    const [isEmpty, setIsEmpty] = useState(false)

    let navigate = useNavigate();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setSelectedRow(null)
    };

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        // Tooltip,
        Legend
    );


    useEffect(() => {
        if (tableData.length === 0 && isEmpty === false) {
            getProductsList(true);
            getHistoricalList();
        }
    });

    const setGlobalState = (alerType, alertTitle, alertBody, showAlert) => {
        globalStates.alertType.set(alerType);
        globalStates.alertTitle.set(alertTitle);
        globalStates.alertBody.set(alertBody);
        globalStates.showAlert.set(showAlert);

        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 3000);
    }

    const handleTimelineClick = (row) => {
        let selected;
        historical.map(key => {
            if (row === key.id) {
                selected = key;
            }
        })
        setOpen(true)
        setSelectedRow(selected)
    }


    const getHistoricalList = () => {
        api.historical.get()
            .then(response => {
                let tempLabels = []
                let tempSelling = []
                let tempCM = []
                let tempCost = []
                let len = 0;
                response.data.map(key => {
                    len = key.history.length - 1
                    tempSelling.push(key.history[len]['selling_price'])
                    tempCM.push(key.history[len]['selling_price'] - key.history[len]['cost'])
                    tempCost.push(key.history[len]['cost'])
                    tempLabels.push(key.name)
                })
                let dataset = {
                    labels: tempLabels,
                    datasets: [
                        {
                            label: "Selling Price",
                            data: tempSelling,
                            backgroundColor: 'rgba(255, 99, 132, 0.5)'
                        },
                        {
                            label: "Cost",
                            data: tempCost,
                            backgroundColor: 'rgba(53, 162, 235, 0.5)'
                        },
                        {
                            label: "Contribution Margin",
                            data: tempCM,
                            backgroundColor: 'rgba(255, 206, 86, 0.5)'
                        }
                    ]
                }
                setHistorical(response.data)
                setHistoricalData(dataset);

            })

    }

    const getProductsList = () => {
        setIsTableLoading(true);

        api.products.get()
            .then(response => {
                let products = response.data.results.map(product => {
                    return {
                        name: product.name,
                        sellingPrice: product.selling_price,
                        costOfUoM: product.cost + "/" + product.unit_of_measure,
                        qty: product.quantity_per_uom,
                        totalCost: product.cost,
                        grossProfit: product.gross_profit,
                        action: product.id,
                        rawData: product
                    }
                })
                setTableData(products)
                if (products.length === 0) {
                    setIsEmpty(true)
                    setIsTableLoading(false)
                }
                else {
                    setIsEmpty(false)
                }


            })
            .catch(error => {
                setGlobalState("error", "Error", "Failed to retrieve product list.", true)

            })
            .finally(() => {
                setIsTableLoading(false);
            });

    }

    const getTableHeaders = useMemo(() => {
        return [
            { id: "name", name: "Product Name", sortable: true },
            { id: "sellingPrice", name: "Selling Price", sortable: true },
            { id: "costOfUoM", name: "Cost of UoM", sortable: true },
            { id: "qty", name: "Quantity per UoM", sortable: true },
            { id: "totalCost", name: "Total Variable Cost", sortable: true },
            { id: "grossProfit", name: "Contribution Margin", sortable: true },
            { id: "action", name: "Action", sortable: false, align: "center" },
        ];
    }, []);


    return (
        <>
            <div
                style={{
                    backgroundColor: "white",
                    height: window.innerWidth <= 600 ? "80vh" : "auto",
                    width: window.innerWidth <= 600 ? "80vw" : "auto",
                    position: "relative"
                }}>
                <div className="flex-justify-between" style={{ alignItems: "flex-start" }}>
                    <p className="text-header">Products Contribution</p>
                </div>
                {historicalData.length !== 0 ?
                    <Bar options={options} data={historicalData} /> : null}
            </div>
            <div style={{ width: "100%" }}>
                <div className="flex-justify-between" style={{ alignItems: "flex-start" }}>
                    <p className="text-header">Products</p>
                </div>
                <div className="flex-justify-between" style={{ alignItems: "flex-end", marginBottom: "10px" }}>
                    <p style={{ marginTop: "20px" }}><b>Total No. of Products:</b> {tableData.length}</p>
                </div>
                <CustomTable
                    headers={getTableHeaders}
                    data={tableData}
                    isLoading={isTableLoading}
                    noDataText={"No Product Found"}
                    handleTimelineClick={handleTimelineClick}
                    module={"Product Contribution"}
                />
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <HistoricalData
                    row={selectedRow}
                    handleClose={handleClose}
                />


            </Modal>

        </>
    )
}

export default Products;