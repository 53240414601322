/*******************************************************************************************
//  * File Name                : Login
//  * File Directory           : src/components/module/authentication/Login
//  * Module                   : Login
//  * Author                   : Ava
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : Home 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Login        -   Login maintenance page
 *
 * #How to call:
 *    <Login/>
 *
 *******************************************************************************************/
import { useState } from "react";
import { useState as useHookState } from "@hookstate/core";
import CustomTextField from "../../shared/CustomTextField";
import CustomButton from "../../shared/CustomButton";
import ConstantStyles from "../../../styles/ConstantStyles.json";
import GlobalStates from "../../../utils/GlobalStates";
import api from "../../../utils/Endpoints";
import {
    Modal,
    Box
} from "@mui/material";
import SignupModal from "./SignupModal"
// json
import PremiumOrganizations from "../../../utils/PremiumOrganizations.json"
import ForgotPasswordModal from "./ForgotPasswordModal"


//import { LoginUser } from "../../../utils/Endpoints";
const Login = () => {
    const globalStates = useHookState(GlobalStates);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [open, setOpen] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const handleClose = () => {
        setOpen(false)
    };

    const handleClosePassword = () => {
        setOpenPassword(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (username !== "" && password !== "") {
            setIsLoggingIn(true);

            let body = { username, password };

            api.auth
                .login(body)
                //            LoginUser(body)
                .then(response => {
                    const { accessToken, refreshToken } = response.data;
                    localStorage.setItem('token', JSON.stringify({ accessToken, refreshToken }));
                    window.localStorage.setItem('isSignedIn', true);
                    window.localStorage.setItem('userInfo', (response.data ?? null) === null ? null : JSON.stringify(response.data));
                    if (PremiumOrganizations.includes(response.data.organization))
                        window.localStorage.setItem("premium", true)
                    else
                        window.localStorage.setItem("premium", false)
                    window.location.href = "/";

                })
                .catch(error => {
                    // console.log(error);
                    setIsLoggingIn(false);

                    if ((error?.response?.data?.message ?? "").toLowerCase().includes("incorrect")) {
                        globalStates.alertBody.set("Incorrect email or password.");
                    }
                    else {
                        globalStates.alertBody.set("Failed to log in, please try again later.");
                    }

                    globalStates.alertType.set("error");
                    globalStates.alertTitle.set("Error");
                    globalStates.showAlert.set(true);

                    setTimeout(() => {
                        globalStates.showAlert.set(false);
                    }, 3000);
                });
        }
        else {
            globalStates.alertType.set("error");
            globalStates.alertTitle.set("Error");
            globalStates.alertBody.set("Please input email and password.");
            globalStates.showAlert.set(true);

            setTimeout(() => {
                globalStates.showAlert.set(false);
            }, 3000);
        }
    }

    return (
        <div className="login">
            <div className="login-container">
                <form className="login-form" onSubmit={handleSubmit}>
                    <p style={{ color: ConstantStyles.primary, fontSize: "26px", fontWeight: 700, textAlign: "center" }}>Welcome</p>
                    <p style={{ color: ConstantStyles.primary, fontSize: "26px", fontWeight: 500, fontFamily: "Calibri (Body) !important", marginBottom: "30px", textAlign: "center" }}>Stevn Books</p>
                    <CustomTextField
                        value={username}
                        onChange={setUsername}
                        placeholder={"Enter Email"}
                        type="email"
                        id="email"
                        disabled={isLoggingIn}
                        customSX={{
                            padding: "12px 20px",
                            marginBottom: "20px"
                        }}
                    />
                    <CustomTextField
                        value={password}
                        onChange={setPassword}
                        placeholder={"Enter Password"}
                        type="password"
                        id="password"
                        disabled={isLoggingIn}
                        customSX={{
                            padding: "12px 20px",
                            marginBottom: "10px"
                        }}
                    />
                    <p
                        style={{ cursor: "pointer", textAlign: "right", marginBottom: "20px" }}
                        onMouseOver={(event) => event.target.style.textDecoration = "underline"}
                        onMouseLeave={(event) => event.target.style.textDecoration = "none"}
                        onClick={() => {
                            console.log("password")
                            setOpenPassword(true)
                            setRefresh(!refresh)
                        }}

                    >Forgot Password?
                    </p>
                    <CustomButton
                        buttonLabel={isLoggingIn ? "Logging in..." : "Login"}
                        onClick={handleSubmit}
                        type="submit"
                        disabled={isLoggingIn}
                        customSX={{
                            width: "100%",
                            padding: "12px 20px"
                        }}
                    />
                    <br />
                    <br />
                    <CustomButton
                        buttonLabel="Sign Up"
                        onClick={() => {
                            setOpen(true)
                            setRefresh(!refresh)
                        }}
                        // type="submit"
                        variant="outlined"
                        customSX={{
                            width: "100%",
                            padding: "12px 20px"
                        }}
                    />

                </form>
                <div style={{
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <SignupModal
                            handleClose={handleClose}
                        />
                    </Modal>
                </div>
                <div style={{
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Modal
                        open={openPassword}
                        onClose={handleClosePassword}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <ForgotPasswordModal
                            handleClose={handleClosePassword}
                        />
                    </Modal>
                </div>
            </div>
        </div>
    )


}


export default Login;
