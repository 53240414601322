import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import { Grid } from "@mui/material";
import ConstantStyles from "../../../styles/ConstantStyles.json";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Link } from "react-router-dom";
import CustomTextField from "../../shared/CustomTextField";
import CustomButton from "../../shared/CustomButton";
import GlobalStates from "../../../utils/GlobalStates";
import api from "../../../utils/Endpoints";
import { Country, State, City } from "country-state-city";
import Select from "react-select";


function CreateIngredient(props) {
    const globalStates = useHookState(GlobalStates);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password2, setPassword2] = useState("");
    const [organization, setOrganization] = useState("");
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;


    const setGlobalState = (alerType, alertTitle, alertBody, showAlert) => {
        globalStates.alertType.set(alerType);
        globalStates.alertTitle.set(alertTitle);
        globalStates.alertBody.set(alertBody);
        globalStates.showAlert.set(showAlert);

        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 3000);
    }


    function CheckPassword(inputtxt) {

        if (inputtxt.value.match(passw)) {
            alert('Correct, try another...')
            return true;
        }
        else {
            alert('Wrong...!')
            return false;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // console.log(password)
        // console.log(passw.test(password))
        //        console.log(password.match("/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/"))
        if ((password !== password2) && (password !== "")) {
            setGlobalState("error", "Error", "Password does not match", true);
        }
        else if (passw.test(password)) {
            if (password !== "" && email !== "" &&
                firstName !== "" && lastName !== "" && password2 !== "" && organization !== "") {
                let body = {}
                setIsLoggingIn(true);
                api.organization.get()
                    .then(response => {
                        let newOrganization = true;
                        let orgId = "";
                        let repeat = true;
                        let tempOrg = organization

                        while (repeat === true) {
                            response.data.map(key => {
                                if (key['name'].toLowerCase() === tempOrg.toLowerCase()) {
                                    newOrganization = false;
                                    orgId = key['id']
                                    tempOrg = tempOrg + Math.random().toString(36).substring(2, 7);
                                    repeat = true
                                }
                            })
                            if (newOrganization === false) {
                                repeat = true;
                                newOrganization = true;
                            }
                            else
                                repeat = false;
                        }


                        api.organization.post({ name: tempOrg })
                            .then(orgRes => {
                                body = {
                                    username: email,
                                    password: password,
                                    email: email,
                                    first_name: firstName,
                                    last_name: lastName,
                                    organization: orgRes.data.id,
                                    country: selectedCountry?.name,
                                    city: selectedState?.name
                                }
                                api.auth.signUp(body)
                                    .then(signRes => {
                                        setGlobalState("success", "Success", "Successful Sign Up! You can now log in with your account.", true);
                                        setTimeout(() => {
                                            globalStates.showAlert.set(false);
                                            window.location.href = "/";
                                        }, 3000);

                                    })
                                    .catch(error => {
                                        // console.log(error);
                                        setIsLoggingIn(false);

                                        setGlobalState("error", "Error", "Failed to Sign up. Please try again", true);

                                        setTimeout(() => {
                                            globalStates.showAlert.set(false);
                                        }, 3000);
                                    });
                            })

                    })

            }
            else {
                setGlobalState("error", "Error", "Please fill up required fields (*)", true);

            }
        }
        else {
            setGlobalState("error", "Error", "Password should contain at least 1 number, 1 special character, 1 uppercase, 1 lowercase and minimum of 8 characters to ensure that our password is safe.", true);
        }
    }

    return (
        <>
            <div
                style={{
                    width: window.innerWidth > 600 ? "50%" : "80%",
                    height: window.innerWidth > 600 ? "70%" : "50%",
                    overflowY: "auto",
                    backgroundColor: ConstantStyles.white,
                    borderRadius: ConstantStyles["default-border-radius"],
                    marginTop: "10%",
                    marginLeft: window.innerWidth > 600 ? "25%" : "10%",
                    position: "relative",
                }}
            >
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={1} sm={1} md={1} lg={1} style={{ marginBottom: "3%" }} />

                    <Grid item xs={10} sm={10} md={10} lg={10} className="text-header" style={{ marginBottom: "3%" }}>
                        Sign Up For Free
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} style={{ marginBottom: "3%" }}>
                        <IconButton
                            aria-label="close"
                            onClick={() => props.handleClose()}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={1} lg={1} sm={1} xs={1} />
                    <Grid item xs={10} sm={10} md={5} lg={5}>
                        <CustomTextField
                            value={firstName}
                            onChange={setFirstName}
                            placeholder={"Enter First Name"}
                            type="text"
                            id="firstName"
                            disabled={isLoggingIn}
                            customSX={{
                                padding: "12px 20px",
                                marginBottom: "10px"
                            }}
                        />
                    </Grid>
                    {window.innerWidth < 600 ? <Grid item sm={1} xs={1} /> : null}
                    {window.innerWidth < 600 ? <Grid item sm={1} xs={1} /> : null}

                    <Grid item xs={10} sm={10} md={5} lg={5}>
                        <CustomTextField
                            value={lastName}
                            onChange={setLastName}
                            placeholder={"Enter Last Name"}
                            type="text"
                            id="lastName"
                            disabled={isLoggingIn}
                            customSX={{
                                padding: "12px 20px",
                                marginBottom: "10px"
                            }}
                        />
                    </Grid>

                    <Grid item md={1} lg={1} sm={1} xs={1} />
                    <Grid item md={1} lg={1} sm={1} xs={1} />

                    <Grid item xs={10} sm={10} md={5} lg={5}>
                        <CustomTextField
                            value={email}
                            onChange={setEmail}
                            placeholder={"Enter Email"}
                            type="text"
                            id="email"
                            disabled={isLoggingIn}
                            customSX={{
                                padding: "12px 20px",
                                marginBottom: "10px"
                            }}
                        />

                    </Grid>

                    {window.innerWidth < 600 ? <Grid item sm={1} xs={1} /> : null}
                    {window.innerWidth < 600 ? <Grid item sm={1} xs={1} /> : null}

                    <Grid item xs={10} sm={10} md={5} lg={5}>
                    </Grid>

                    <Grid item md={1} lg={1} sm={1} xs={1} />
                    <Grid item md={1} lg={1} sm={1} xs={1} />

                    <Grid item xs={10} sm={10} md={5} lg={5}>
                        <CustomTextField
                            value={password}
                            onChange={setPassword}
                            placeholder={"Enter Password"}
                            type="password"
                            id="password"
                            disabled={isLoggingIn}
                            customSX={{
                                padding: "12px 20px",
                                marginBottom: "10px"
                            }}
                        />
                    </Grid>

                    {window.innerWidth < 600 ? <Grid item sm={1} xs={1} /> : null}
                    {window.innerWidth < 600 ? <Grid item sm={1} xs={1} /> : null}

                    <Grid item xs={10} sm={10} md={5} lg={5}>
                        <CustomTextField
                            value={password2}
                            onChange={setPassword2}
                            placeholder={"Re-enter Password"}
                            type="password"
                            id="password2"
                            disabled={isLoggingIn}
                            customSX={{
                                padding: "12px 20px",
                                marginBottom: "10px"
                            }}
                        />
                    </Grid>

                    <Grid item md={1} lg={1} sm={1} xs={1} />
                    <Grid item md={1} lg={1} sm={1} xs={1} />

                    <Grid item xs={10} sm={10} md={5} lg={5}>
                        <CustomTextField
                            value={organization}
                            onChange={setOrganization}
                            placeholder={"Enter Organization"}
                            type="text"
                            id="organization"
                            disabled={isLoggingIn}
                            customSX={{
                                padding: "12px 20px",
                                marginBottom: "10px"
                            }}
                        />
                    </Grid>

                    <Grid item md={6} lg={6} sm={1} xs={1} />


                    <Grid item md={1} lg={1} sm={1} xs={1} />

                    <Grid item xs={10} sm={10} md={5} lg={5}>
                        <Select
                            placeholder={'Country'}
                            options={Country.getAllCountries()}
                            getOptionLabel={(options) => {
                                return options["name"];
                            }}
                            getOptionValue={(options) => {
                                return options["name"];
                            }}
                            value={selectedCountry}
                            onChange={(item) => {
                                setSelectedCountry(item);
                                setSelectedState(null);
                            }}
                        />
                    </Grid>
                    {window.innerWidth < 600 ? <Grid item sm={1} xs={1} /> : null}
                    {window.innerWidth < 600 ? <Grid item sm={1} xs={1} /> : null}

                    <Grid item xs={10} sm={10} md={5} lg={5}>
                        <Select
                            placeholder={'Province/State/City'}
                            options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                            getOptionLabel={(options) => {
                                return options["name"];
                            }}
                            getOptionValue={(options) => {
                                return options["name"];
                            }}
                            value={selectedState}
                            onChange={(item) => {
                                setSelectedState(item);
                                setSelectedCity(null);
                            }}
                        />
                    </Grid>

                    <Grid item md={1} lg={1} sm={1} xs={1} />

                    <Grid item md={3} lg={3} sm={1} xs={1} />

                    <Grid item xs={10} sm={10} md={6} lg={6}>
                        <CustomButton
                            buttonLabel={isLoggingIn ? "Signing Up..." : "Sign Up For Free"}
                            onClick={handleSubmit}
                            type="submit"
                            disabled={isLoggingIn}
                            customSX={{
                                width: "100%",
                                padding: "12px 20px"
                            }}
                        />
                    </Grid>
                    <Grid item md={3} lg={3} sm={1} xs={1} />

                </Grid>
                <br />
                <br />
            </div>
        </>
    );
}

export default CreateIngredient;