import axios from 'axios';

let rootUrl = "https://billing-provisional-backend.herokuapp.com";
let apiKey = "b966a377-4631-4c33-83c8-fbd44a274d0e";
// POST login user

const ax = axios.create({
    baseURL: "https://dev.cp-calculator.com/api",
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    withCredentials: true,
});




const api = {
    auth: {
        login(body) {
            return ax.post("/auth/login", new URLSearchParams({ ...body }), {
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
            });
        },
        refresh() {
            const token = JSON.parse(localStorage.getItem("token") ?? "{}");
            return ax.post("/auth/refresh", { ...token });
        },
        logout() {
            const token = JSON.parse(localStorage.getItem("token") ?? "{}");
            return ax.post("/auth/logout", { refreshToken: token.refreshToken });
        },
        signUp(body) {
            return ax.post("/auth/signup", new URLSearchParams({ ...body }), {
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
            });
        },
        get_reset(body) {
            return ax.post(`auth/reset`, new URLSearchParams({ ...body }), {
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
            });
        },
        post_reset(body) {
            return ax.post(`auth/reset/confirm`, new URLSearchParams({ ...body }), {
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
            });
        }
    },
    ingredients: {
        get() {
            return ax.get("/ingredient");
        },
        post(body) {
            return ax.post("/ingredient", body);
        },
        delete(ingredient_id) {
            return ax.delete(`/ingredient/${ingredient_id}`);
        },
        put(ingredient_id, body) {
            return ax.put(`/ingredient/${ingredient_id}`, body);
        }
    },
    products: {
        get() {
            return ax.get("/product");
        },
        post(body) {
            return ax.post('/product', body);
        },
        put(product_id, body) {
            return ax.put(`/product/${product_id}`, body);
        },
        delete(product_id) {
            return ax.delete(`/product/${product_id}`);
        }
    },
    productsIngredients: {
        get() {
            return ax.get("/productIngredient")
        },
        post(body) {
            return ax.post("/productIngredient", body)
        },
        put(productIngredient_id, body) {
            return ax.put(`/productIngredient/${productIngredient_id}`, body);
        },
        delete(productIngredient_id) {
            return ax.delete(`/productIngredient/${productIngredient_id}`);
        }
    },
    historical: {
        get() {
            return ax.get("/historical")
        }
    },
    organization: {
        get() {
            return ax.get("/organization")
        },
        post(body) {
            return ax.post("/organization", body)
        }
    },
    audit: {
        product_get() {
            return ax.get("/audit/product")
        },
        ingredients_get() {
            return ax.get("/audit/ingredient")
        }
    },
    user: {
        get() {
            return ax.get("/user")
        }
    },
    country: {
        get() {
            return ax.get("/country")
        }
    },
    payment:{
        get() {
            return ax.get("/subscriptionPlan")
        },
        post(body){
            return ax.post("/checkout", body)
        }
    }
}

ax.interceptors.request.use(request => {
    const token = JSON.parse(localStorage.getItem('token') ?? '{}')
    if (token.accessToken && request.url !== "/auth/refresh") {
        request.headers['Authorization'] = `Bearer ${token.accessToken}`;
    }
    return request;
}, error => error);

ax.interceptors.response.use(response => response, async (error) => {
    if (error.response.status === 401 && error.config.url !== "/auth/refresh") {
        try {
            let res = await api.auth.refresh();
            let { accessToken } = res.data;
            let token = JSON.parse(localStorage.getItem("token") ?? "{}");
            token.accessToken = accessToken;
            localStorage.setItem("token", JSON.stringify(token));
            try {
                let res = await ax.request(error.config);
                return Promise.resolve(res);
            } catch (e) {
                return Promise.reject(e);
            }
        } catch (e) {
            window.localStorage.clear()
            window.location.href = "/"
            return Promise.reject(e);
        }
    }
    return Promise.reject(error);
});

export default api;



// POST login user
export function LoginUser(body) {
    const authOptions = {
        method: "post",
        url: `${rootUrl}/api/authenticate/sign-in`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            "Authorization": apiKey
        }
    };

    return axios(authOptions);
}

// POST create employee
export function CreateEmployee(body) {
    const authOptions = {
        method: "post",
        url: `${rootUrl}/api/authenticate/create-user`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            "Authorization": apiKey
        }
    };

    return axios(authOptions);
}

// PUT update employee
export function UpdateEmployee(body) {
    const authOptions = {
        method: "put",
        url: `${rootUrl}/api/authenticate/update-info`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            "Authorization": apiKey
        }
    };

    return axios(authOptions);
}

// GET employee list
export function GetEmployeeList(body) {
    const authOptions = {
        method: "post",
        url: `${rootUrl}/api/authenticate/fetch-user`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            "Authorization": apiKey
        }
    };

    return axios(authOptions);
}

// GET employee roles
export function GetEmployeeRoles() {
    const authOptions = {
        method: "get",
        url: `${rootUrl}/api/authenticate/user-roles`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": apiKey
        }
    };

    return axios(authOptions);
}

// GET subscriber plans roles
export function GetSubscriberPlans() {
    const authOptions = {
        method: "get",
        url: `${rootUrl}/api/billing/subscription-plan`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": apiKey
        }
    };

    return axios(authOptions);
}

// POST create employee role
export function CreateEmployeeRole(body) {
    const authOptions = {
        method: "post",
        url: `${rootUrl}/api/authenticate/user-roles/create`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            "Authorization": apiKey
        }
    };

    return axios(authOptions);
}

// PATCH update employee role
export function UpdateEmployeeRole(roleId, body) {
    const authOptions = {
        method: "patch",
        url: `${rootUrl}/api/authenticate/user-roles/update-role/${roleId}`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            "Authorization": apiKey
        }
    };

    return axios(authOptions);
}

// PATCH deactivate employee role
export function DeactivateEmployeeRole(roleId, body) {
    const authOptions = {
        method: "patch",
        url: `${rootUrl}/api/authenticate/user-roles/delete-role/${roleId}`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            "Authorization": apiKey
        }
    };

    return axios(authOptions);
}

// POST get subscriber list
export function GetSubscriberList(body) {
    const authOptions = {
        method: "post",
        url: `${rootUrl}/api/billing/subscriber/fetch-subscriber`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            "Authorization": apiKey
        }
    };

    return axios(authOptions);
}

// POST validate subscriber
export function ValidateSubscriber(body) {
    const authOptions = {
        method: "post",
        url: `${rootUrl}/api/billing/subscriber/validate-subscriber`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            "Authorization": apiKey
        }
    };

    return axios(authOptions);
}
