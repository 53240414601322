import { useState } from "react";
import moment from "moment";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { 
    TextField
} from "@mui/material";
import PropTypes from "prop-types";
import ConstantStyles from "../../styles/ConstantStyles.json";

function CustomDateTimePicker(props) {
    const [isOpen, setIsOpen] = useState(false);
    
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
                inputFormat={props.inputFormat}
                views={props.views}
                value={props.value}
                onChange={(value) => props.onChange(value, ...props.additionalParameters)}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                renderInput={(params) => 
                    <TextField 
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                            placeholder: "Select date",
                            style: {
                                padding: "6px 10px",
                                outline: "none",
                                ...props.custominputProps,
                            }
                        }}
                        sx={{
                            "&.MuiFormControl-root": {
                                width: props.width,
                                backgroundColor: `${props.disabled ? ConstantStyles["light-grey"] : ConstantStyles.white} !important`,
                                borderRadius: ConstantStyles["default-border-radius"],
                                color: ConstantStyles["default-text-color"],
                                outline: "none",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderWidth: "1px !important",
                                    borderColor: isOpen ? ConstantStyles.primary : ConstantStyles["line-border-grey"],
                                    marginTop: "5px"
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: isOpen ? ConstantStyles.primary : ConstantStyles["line-border-grey"] 
                                },
                                "&:hover .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: ConstantStyles.primary
                                },
                                "& .MuiOutlinedInput-notchedOutline legend": {
                                    display: "none"
                                }
                            }
                        }}
                        variant="outlined"
                        onClick={() => !props.disabled && setIsOpen(!isOpen)}
                    />
                }
                disabled={props.disabled}
                disableFuture={props.disableFuture}
                disablePast={props.disablePast}
            />
        </LocalizationProvider>
    )
}

CustomDateTimePicker.propTypes = {
    inputFormat: PropTypes.string,
    customSX: PropTypes.object,
    views: PropTypes.array,
    value: PropTypes.object,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    width: PropTypes.string,
    custominputProps: PropTypes.object,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    additionalParameters: PropTypes.array
};

CustomDateTimePicker.defaultProps = {
    inputFormat: "MM/YYYY",
    customSX: {},
    views: ["month", "year"],
    value: null,
    onChange: () => {},
    disabled: false,
    width: "100%",
    custominputProps: {},
    disableFuture: false,
    disablePast: false,
    additionalParameters: []
};

export default CustomDateTimePicker;