import { Button } from "@mui/material";
import PropTypes from "prop-types";

function CustomButton(props) {

    return (
        <>
            <Button
                id={`button-${props.id}`}
                sx={{
                    padding: "5.25px 12px",
                    boxShadow: "none",
                    width: "auto",
                    maxWidth: "none",
                    height: "auto",
                    "&:hover": {
                        boxShadow: "none"
                    },
                    ...props.customSX
                }}
                variant={props.variant}
                color={props.buttonColor}
                onClick={props.onClick}
                disabled={props.disabled}
                type={props.type}
            >{props.buttonLabel}</Button>
        </>
    );
}

CustomButton.propTypes = {
    id: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonColor: PropTypes.string,
    customSX: PropTypes.object,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(["button", "submit", "reset"]),
    variant: PropTypes.string
};

CustomButton.defaultProps = {
    id: "",
    buttonLabel: "Button",
    buttonColor: "primary",
    customSX: {},
    disabled: false,
    onClick: () => {},
    type: "button",
    variant: "contained"
};

export default CustomButton;
