import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import moment from "moment";
import CustomButton from "../../shared/CustomButton";
import CustomTable from "../../shared/CustomTable";
import GlobalStates from '../../../utils/GlobalStates';
import api from "../../../utils/Endpoints";
import { Link, useNavigate } from "react-router-dom";



function Products() {
    const globalStates = useHookState(GlobalStates);

    const [isTableLoading, setIsTableLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [tableDataIngredients, setTableDataIngredients] = useState([]);
    const [historical, setHistorical] = useState([])
    const [historicalData, setHistoricalData] = useState([])
    const [open, setOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState([])
    const [users, setUsers] = useState([])
    const [dictUsers, setDictUsers] = useState({})
    let navigate = useNavigate();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setSelectedRow(null)
    };

    useEffect(() => {
        if (users.length === 0) {
            getUsers()
        }
        if (users.length !== 0 && (isTableLoading === true)) {
            getAuditProduct();
            getAuditIngredients();
        }
    }, [users.length]);

    const setGlobalState = (alerType, alertTitle, alertBody, showAlert) => {
        globalStates.alertType.set(alerType);
        globalStates.alertTitle.set(alertTitle);
        globalStates.alertBody.set(alertBody);
        globalStates.showAlert.set(showAlert);

        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 3000);
    }

    const getUsers = () => {
        api.user.get()
            .then(response => {
                let dict = {}
                let list = response.data.map(row => {
                    dict[row.id] = row.username;

                    return {
                        id: row.id,
                        username: row.username
                    }
                })

                setDictUsers(dict)
                setUsers(list)
            })
    }

    const getAuditProduct = () => {
        api.audit.product_get()
            .then(response => {
                let audit = response.data.results.map(row => {
                    return {
                        date: new Date(row.modified).toString().replace("GMT+0800 (China Standard Time)", ""),
                        audit_action: row.action,
                        name: row.name,
                        qty: row.quantity_per_uom,
                        sellingPrice: row.selling_price,
                        uom: row.unit_of_measure,
                        cost: row.cost,
                        totalCost: row.cost,
                        createdBy: dictUsers[row.modified_by]
                    }
                })
                setTableData(audit)
            })
            .catch(error => {
                setGlobalState("error", "Error", "Failed to retrieve product list.", true)

            })
            .finally(() => {
                setIsTableLoading(false);
            });
    }

    const getAuditIngredients = () => {
        api.audit.ingredients_get()
            .then(response => {
                console.log(response, dictUsers)
                let audit = response.data.results.map(row => {
                    console.log(row.modified_by)
                    console.log(dictUsers[row.modified_by])
                    return {
                        date: new Date(row.modified).toString().replace("GMT+0800 (China Standard Time)", ""),
                        audit_action: row.action,
                        name: row.name,
                        purchasingPrice: row.purchasing_price,
                        shippingCost: row.shipping_cost,
                        uom: row.unit_of_measure,
                        createdBy: dictUsers[row.modified_by]
                    }
                })
                setTableDataIngredients(audit)

            })
            .catch(error => {
                setGlobalState("error", "Error", "Failed to retrieve product list.", true)
            })
            .finally(() => {
                setIsTableLoading(false);
            });

    }


    const getTableHeaders = useMemo(() => {
        return [
            { id: "date", name: "Date", sortable: false },
            { id: "audit_action", name: "Action", sortable: false },
            { id: "name", name: "Product Name", sortable: false },
            { id: "sellingPrice", name: "Selling Price", sortable: false },
            { id: "uom", name: "UoM", sortable: false },
            { id: "totalCost", name: "Total Variable Cost", sortable: false },
            { id: "createdBy", name: "Created By", sortable: false },
        ];
    }, []);

    const getTableHeadersIngredients = useMemo(() => {
        return [
            { id: "date", name: "Date", sortable: false },
            { id: "audit_action", name: "Action", sortable: false },
            { id: "name", name: "Product Name", sortable: false },
            { id: "purchasingPrice", name: "Purchasing Price", sortable: false },
            { id: "shippingCost", name: "Shipping Cost", sortable: false },
            { id: "uom", name: "UoM", sortable: false },
            { id: "createdBy", name: "Created By", sortable: false },
        ];
    }, []);

    return (
        <>
            <div style={{ width: "100%" }}>
                <div className="flex-justify-between" style={{ alignItems: "flex-start" }}>
                    <p className="text-header">Products Audit Trail</p>
                </div>
                <CustomTable
                    headers={getTableHeaders}
                    data={tableData}
                    isLoading={isTableLoading}
                    noDataText={"No Audit Trail Found"}
                    module={"Audit Trail"}
                />
            </div>
            <div style={{ width: "100%" }}>
                <div className="flex-justify-between" style={{ alignItems: "flex-start" }}>
                    <p className="text-header">Ingredients Audit Trail</p>
                </div>
                <CustomTable
                    headers={getTableHeadersIngredients}
                    data={tableDataIngredients}
                    isLoading={isTableLoading}
                    noDataText={"No Audit Trail Found"}
                    module={"Audit Trail"}
                />
            </div>

        </>
    )
}

export default Products;