import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import moment from "moment";
import CustomButton from "../../shared/CustomButton";
import CustomTable from "../../shared/CustomTable";
import GlobalStates from '../../../utils/GlobalStates';
import api from "../../../utils/Endpoints";
import { Link, useNavigate } from "react-router-dom";
import {
    Box
} from "@mui/material";



function Payment() {
    const globalStates = useHookState(GlobalStates);
    const [refresh, setRefresh] = useState(false);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const userInfo = JSON.parse(window.localStorage.getItem("userInfo"))
    useEffect(() => {
        // const options = {
        //     method: 'GET',
        //     headers: {
        //         accept: 'application/json',
        //         authorization: 'Basic c2tfdGVzdF9TUExrN1VXNE1qRFBCTEJLTHZCWDY1eHU6'
        //     }
        // };

        api.payment.get()
            .then(response => {
                console.log(response)

                let tempPlan = []
                response.data.map(plan => {
                    tempPlan.push({
                        id: plan.id,
                        tier: plan.tier,
                        name: plan.name,
                        product_limit: plan.product_limit,
                        price: plan.price
                    })
                })
                setSubscriptionPlans(tempPlan)
            })

    }, []);

    const setGlobalState = (alerType, alertTitle, alertBody, showAlert) => {
        globalStates.alertType.set(alerType);
        globalStates.alertTitle.set(alertTitle);
        globalStates.alertBody.set(alertBody);
        globalStates.showAlert.set(showAlert);

        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 3000);
    }




    return (
        <>
            <div style={{
                display: "flex", flexDirection: window.innerWidth > 600 ? "row" : "column",
            }}>
                {subscriptionPlans.map(key => {
                    console.log(key)
                    return (
                        <>
                            <Box
                                sx={{
                                    width: 150,
                                    height: 150,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    // display: 'flex',
                                    marginLeft: window.innerWidth > 600 ? "3%" : "1%",
                                    marginBottom: "3%",
                                    paddingTop: "4%",
                                    borderRadius: "10px 10px 10px 10px",
                                    backgroundColor: 'primary.dark',
                                    '&:hover': {
                                        backgroundColor: 'primary.main',
                                        opacity: [0.9, 0.8, 0.7],
                                    },
                                }}
                                onClick={() => {
                                    console.log(key)


                                    let body = {
                                        "billing": {
                                            "name": "Ava Alimurong",
                                            "email": "ava.alimurong@gmail.com",
                                            "phone": null,
                                            "address": {
                                                "line1": null,
                                                "line2": null,
                                                "city": "Metro Manila",
                                                "state": null,
                                                "postal_code": null,
                                                "country": "PH"
                                            }
                                        },
                                        "description": "Subscription Tier 2",
                                        "line_items": [
                                            {
                                                "amount": 14900,
                                                "currency": "PHP",
                                                "description": "Subscription Tier 2",
                                                "name": "Subscription Tier 2",
                                                "quantity": 1
                                            }
                                        ],
                                        "success_url": "https://stevnbooks.com/dashboard",
                                        "cancel_url": "http://stevnbooks.com/"
                                    }

                                    // let body = {
                                    //     "cancel_url": "http://stevnbooks.com/",
                                    //     "billing": {
                                    //         "name": userInfo.first_name + " " + userInfo.last_name,
                                    //         "email": userInfo.email,
                                    //         "phone": null,
                                    //         "address": {
                                    //             "line1": null,
                                    //             "line2": null,
                                    //             "city": userInfo.city,
                                    //             "state": null,
                                    //             "postal_code": null,
                                    //             "country": "PH"
                                    //         }
                                    //     },
                                    //     "description": key.name,
                                    //     "line_items": [
                                    //         {
                                    //             "amount": key.price * 100,
                                    //             "currency": "PHP",
                                    //             "description": key.name,
                                    //             "images": [],
                                    //             "name": key.name,
                                    //             "quantity": 1
                                    //         }
                                    //     ],
                                    //     "payment_method_types": [
                                    //         "gcash", "paymaya", "card"
                                    //     ],
                                    //     "reference_number": null,
                                    //     "send_email_receipt": true,
                                    //     "show_description": false,
                                    //     "show_line_items": true,
                                    //     "success_url": "https://stevnbooks.com/dashboard",
                                    //     "statement_descriptor": "string",
                                    // }

                                    console.log(body)
                                    api.payment.post(body)
                                        .then(response => {
                                            console.log("IM HERE")
                                            console.log(response)
                                            const checkout_url = response.data.data.attributes.checkout_url
                                            console.log(checkout_url)
                                            window.location.href = checkout_url;
                                            console.log("==Test Data==")
                                        })
                                        .catch(error => {
                                            console.log(error)
                                        })
                                }}
                            >
                                <p style={{
                                    color: "white",
                                    textAlign: "center",
                                }}>
                                    {key.name}
                                </p>
                                <p style={{
                                    color: "white",
                                    textAlign: "center",
                                }}> PHP {key.price} / {key.product_limit} products available
                                </p>
                            </Box >
                        </>
                    )
                })}
            </div >

        </>
    )
}

export default Payment;