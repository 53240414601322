import { createState } from '@hookstate/core';

const GlobalStates = createState({
    isLoading: true,
    isLoggedIn: false,
    
    showAlert: false,
    alertTimer: null,
    alertType: "success",
    alertTitle: "Success",
    alertBody: "Success",

    showConfirmation: false,
    confirmationType: "success",
    confirmationTitle: "Success",
    confirmationBody: "Success",
    confirmationID: null,
    isConfirmed: false,

    showFilterSidebar: false,
    showConfirmationModal: false,
});

export default GlobalStates;