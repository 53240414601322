/*******************************************************************************************
//  * File Name                : Header
//  * File Directory           : src/components/general/Header
//  * Module                   : Header
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : general
//  * Used In				   : Home 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Header        -   component which the navbar and header is combined.
 *
 * #How to call:
 *    <Header/>
 *
 *******************************************************************************************/
import { useState as useHookState } from "@hookstate/core";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import PropTypes from "prop-types";
import GlobalStates from '../../utils/GlobalStates';
import {
    Tooltip,
    IconButton
} from "@mui/material";
import {
    Edit,
    Tune,
    Close
} from "@mui/icons-material";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import api from "../../utils/Endpoints";
const style = {
    headerHeight: {
        minHeight: "25px"
    },
    svgAlign: {
        verticalAlign: "middle"
    }
}
//object for dropdown item
const items = [
    // {
    //     title: "Profile",
    //     link: "/profile"
    // },
    // {
    //     title: "Settings",
    //     link: "/settings"
    // },
    {
        title: "Logout"
    }
]

//main content
const Header = ({ collapsed, handleCollapsedChange }) => {
    const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    const globalStates = useHookState(GlobalStates);

    const logout = () => {
        globalStates.isLoading.set(true);

        api.auth.logout()
            .finally(() => {
                window.localStorage.clear();
                window.location.href = "/";
            });
    }

    return (
        <nav className="navbar navbar-light bg-light p-0 header headerFixed" style={style.headerHeight}>
            {/* <button className="navbar-brand btn pb-0 pt-0 btn-toggle" id="collapseBtn" onClick={handleCollapsedChange}
                checked={collapsed} >
                <img src={MenuIcon} width="20px" alt="menu" style={style.svgAlign} />
            </button> */}

            <Tooltip title={collapsed ? "Show Sidebar" : "Hide Sidebar"} placement="right" arrow>
                <IconButton id="collapseBtn" onClick={handleCollapsedChange}
                    checked={collapsed}>
                    {collapsed ?
                        <MenuIcon color={"primary"} />
                        :
                        <MenuOpenIcon color={"primary"} />
                    }

                </IconButton>
            </Tooltip>
            <DropdownButton id="dropdown-item-button bg-light float-end" variant="secondary" title={userInfo.email ?? "-"}
                align={{ lg: 'end' }}>
                {/* mapping dropdown items */}
                {items.map((item, index) => {
                    if (item.title === "Logout") {
                        return <Dropdown.Item as="button" key={index} onClick={() => logout()} id={item.title}>{item.title}</Dropdown.Item>
                    }
                    else {
                        return (
                            <Link to={item.link} style={{ textDecoration: "none" }} key={index}>
                                <Dropdown.Item as="button" key={item.title} id={item.title}>{item.title}</Dropdown.Item>
                            </Link>
                        )
                    }
                })}
            </DropdownButton>
        </nav>
    );
};


Header.propTypes = {
    collapsed: PropTypes.bool,
    handleToggleSidebar: PropTypes.func
};

Header.defaultProps = {
    collapsed: false,
    handleCollapsedChange: () => { },
};

export default Header;
