import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import { Grid } from "@mui/material";
import ConstantStyles from "../../../styles/ConstantStyles.json";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Link } from "react-router-dom";

function CreateIngredient(props) {
    let links = {
        "Enter New Product": "/create-product",
        "Edit Existing Product": "/products",
        "Audit Trail": "/audit-trail",
        "Products Contribution Margin": "/product-contribution",
        "Coming Soon": "/",
        "Payment": "/payment"
    }
    return (
        <>
            <div
                style={{
                    width: window.innerWidth > 600 ? "25%" : "80%",
                    height: window.innerWidth > 600 ? "23%" : "20%",
                    overflowY: "auto",
                    backgroundColor: ConstantStyles.white,
                    borderRadius: ConstantStyles["default-border-radius"],
                    marginTop: "15%",
                    marginLeft: window.innerWidth > 600 ? "40%" : "10%",
                    position: "relative",
                }}
            >
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={11} sm={11} md={11} lg={11} className="text-header">
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <IconButton
                            aria-label="close"
                            onClick={() => props.handleClose()}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <br />
                {props.selected.map(key => {
                    console.log(key, links[key])
                    return (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ cursor: "pointer", marginLeft: "2%" }}>
                                    {key === "Coming Soon" ? <h6>{key}</h6>
                                        :
                                        <Link to={links[key]}>
                                            <h6>{key}</h6>
                                        </Link>
                                    }
                                </Grid>
                            </Grid>
                            <br />
                        </>
                    )
                })}
            </div>
        </>
    );
}

export default CreateIngredient;