import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import moment from "moment";
import { Grid } from "@mui/material";
import CustomTextField from "../../shared/CustomTextField";
import CustomAutocompleteSelect from "../../shared/CustomAutocompleteSelect";
import CustomButton from "../../shared/CustomButton";
import CustomTooltip from "../../shared/CustomTooltip";
import ConstantStyles from "../../../styles/ConstantStyles.json";
import api from "../../../utils/Endpoints";
import GlobalStates from '../../../utils/GlobalStates';
import { formatNumberRoundOffWithPercent, formatNumberRoundOffWithPeso, formatNumberWCommaRoundOff } from "../../../utils/Formats"
import {
    Tooltip,
    IconButton,
    Modal,
    Box
} from "@mui/material";
import CreateIngredient from "../ingredients/CreateIngredient";
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from "react-router-dom";




function CreateProduct(props) {
    const globalStates = useHookState(GlobalStates);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);
    const [activeStep, setActiveStep] = useState(0);

    //for editing
    const isEdit = window.localStorage.getItem('isEdit') || false;
    const productEditRow = JSON.parse(window.localStorage.getItem('productEditRow')) || {};
    const [editLoad, setEditLoad] = useState(true)
    const [deletedIngredients, setDeletedIngredients] = useState([])

    //product table
    const [name, setName] = useState("");
    const [sellingPrice, setSellingPrice] = useState(0);
    const [shippingCost, setShippingCost] = useState(0);
    const [UoM, setUoM] = useState("");
    const [costPerUoM, setCostPerUoM] = useState(0);
    const [qtyPerUoM, setQtyPerUoM] = useState(1);
    const [totalCost, setTotalCost] = useState(0);
    const [grossProfit, setGrossProfit] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false);
    const [id, setId] = useState("")

    // ingredients table
    const [ingredient, setIngredient] = useState("");
    const [listOfIngredients, setListOfIngredients] = useState([]);
    const [allIngredients, setAllIngredients] = useState([]);
    const [qty, setQty] = useState([])
    const [sum, setSum] = useState(0)
    const [isCompute, setIsCompute] = useState(false);

    // modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    let navigate = useNavigate();
    const handleClose = (value) => {
        setOpen(false);
        getAllIngredients()
        if (value.submitted === true) {
            let item = {
                label: value.body.name,
                raw: {
                    unit_of_measure: value.body.unit_of_measure,
                    purchasing_price: value.body.purchasing_price,
                    shipping_cost: value.body.shipping_cost,
                }
            }
            addIngredients(item)
            setRefresh(!refresh)
        }
    };

    useEffect(() => {
        if (allIngredients.length === 0) {
            getAllIngredients()
        }

        if (isEdit === "true" && editLoad === true) {
            setName(productEditRow.name)
            setSellingPrice(productEditRow.sellingPrice)
            setUoM(productEditRow.rawData.unit_of_measure)
            setShippingCost(productEditRow.rawData.shipping_cost)
            setQtyPerUoM(productEditRow.rawData.quantity_per_uom)
            setId(productEditRow.rawData.id)
            setEditLoad(false)


            if (listOfIngredients.length === 0)
                getExistingIngredients(productEditRow.rawData.id)
        }
    })

    useEffect(() => {
        if (isCompute === true) {
            let tempList = listOfIngredients;
            tempList.map(key => {
                key['percentage'] = formatNumberRoundOffWithPercent(((parseFloat(key['cost']) * parseFloat(key['quantity']) / sum) * 100), 2)
            })
            setListOfIngredients(tempList)
            setIsCompute(false)
        }

    }, [isCompute])


    const setGlobalState = (alerType, alertTitle, alertBody, showAlert) => {
        globalStates.alertType.set(alerType);
        globalStates.alertTitle.set(alertTitle);
        globalStates.alertBody.set(alertBody);
        globalStates.showAlert.set(showAlert);

        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 3000);
    }

    const getExistingIngredients = (id) => {
        api.productsIngredients.get()
            .then(response => {
                let tempList = []
                let item = {}
                let tempSum = 0
                let tempQty = []
                response.data.results.map(key => {
                    if (key.product === id) {
                        item = {
                            id: key.id,
                            name: key.ingredient.name,
                            quantity: parseFloat(key.quantity),
                            unit: key.ingredient.unit_of_measure,
                            cost: parseFloat(key.ingredient.purchasing_price) + parseFloat(key.ingredient.shipping_cost),
                            totalCost: parseFloat(key.ingredient.purchasing_price) + parseFloat(key.ingredient.shipping_cost),
                            raw: key.ingredient
                        }
                        tempList.push(item)
                        tempQty.push(parseFloat(key.quantity))
                        tempSum = parseFloat(tempSum) + (parseFloat(key.quantity) * (parseFloat(key.ingredient.purchasing_price) + parseFloat(key.ingredient.shipping_cost)))
                    }
                })
                setListOfIngredients(tempList)
                setQty(tempQty)
                setSum(parseFloat(tempSum))
                setIsCompute(true)
                setTimeout(() => {
                    setIngredient("")
                }, 3000);
            })
            .catch(error => {
                console.log(error)
                setGlobalState("error", "Error", "Failed to retrieve ingredients list.", true)
            })
            .finally(() => {
                setIsTableLoading(false);
            });


    }

    const getAllIngredients = () => {
        setIsTableLoading(true);

        api.ingredients.get()
            .then(response => {
                let ingredients = response.data.results.map(ing => {
                    return {
                        label: ing.name,
                        id: ing.id,
                        raw: ing
                    }
                })
                setAllIngredients(ingredients)
            })
            .catch(error => {
                setGlobalState("error", "Error", "Failed to retrieve ingredients list.", true)
            })
            .finally(() => {
                setIsTableLoading(false);
            });

    }

    const addIngredients = (value) => {
        setIngredient(value)
        let item = {
            name: value.label,
            quantity: 1,
            unit: value.raw.unit_of_measure,
            cost: parseFloat(value.raw.purchasing_price) + parseFloat(value.raw.shipping_cost),
            totalCost: parseFloat(value.raw.purchasing_price) + parseFloat(value.raw.shipping_cost),
            raw: value.raw
        }
        let tempSum = parseFloat(sum) + parseFloat(value.raw.purchasing_price) + parseFloat(value.raw.shipping_cost)
        setListOfIngredients([...listOfIngredients, item])
        setQty([...qty, 1])
        setSum(parseFloat(tempSum))
        setIsCompute(true)
        setTimeout(() => {
            setIngredient("")
        }, 3000);
    }


    const handleSubmit = (e) => {
        let body = {}
        if (name === "" || sellingPrice <= 0 || shippingCost < 0 || UoM === "" || qtyPerUoM < 0) {
            setGlobalState("error", "Error", "Please fill up Required fields", true)
        }
        else if (listOfIngredients.length === 0) {
            setGlobalState("error", "Error", "Ingredients List should include one or more ingredients", true)
        }
        else if (isEdit === "true") {
            body = {
                "id": id,
                "name": name,
                "cost": parseFloat(sum),
                "shipping_cost": shippingCost,
                "unit_of_measure": UoM,
                "selling_price": sellingPrice,
                "quantity_per_uom": qtyPerUoM,
            }

            api.products.put(id, body)
                .then(response => {
                    deletedIngredients.map(key => {
                        api.productsIngredients.delete(key)
                            .then(response => {
//                                console.log("DELETED INGREDIENT")
                            })
                    })

                    listOfIngredients.map(key => {
                        let ing = {}
                        if (Object.keys(key).includes("id")) {
                            ing = {
                                id: key.id,
                                "quantity": key.quantity,
                                "product": response.data.id,
                                "ingredient": key.raw.id
                            }
                            api.productsIngredients.put(key.id, ing).then(response => {
         //                       console.log("EDIT INGREDIENTS")
                            })
                        }
                        else {
                            ing = {
                                "quantity": key.quantity,
                                "product": response.data.id,
                                "ingredient": key.raw.id
                            }
                            console.log(ing)
                            api.productsIngredients.post(ing).then(response => {
             //                   console.log("ADDED INGREDIENTS")
                            })
                        }
                    })
                    setGlobalState("success", "Success", "Successfully edited product.", true)


                })
                .catch(error => {
                    console.log(error)
                    setGlobalState("error", "Error", "Failed to save new product.", true)
                })
                .finally(() => {
                    setIsTableLoading(false);
                });

        }
        else {

            body = {
                "name": name,
                "cost": parseFloat(sum),
                "shipping_cost": shippingCost,
                "unit_of_measure": UoM,
                "selling_price": sellingPrice,
                "quantity_per_uom": qtyPerUoM,
            }
            api.products.post(body)
                .then(response => {
                    listOfIngredients.map(key => {
                        let ing = {}
                        if (Object.keys(key).includes("id")) {
                            ing = {
                                "quantity": key.quantity,
                                "product": response.data.id,
                                "ingredient": key.raw.id
                            }

                        }
                        else {
                            ing = {
                                "quantity": key.quantity,
                                "product": response.data.id,
                                "ingredient": allIngredients.filter(ingredient => ingredient.label === key.name)[0].raw.id
                            }
                        }
                        api.productsIngredients.post(ing).then(response => {
//                            console.log("ADDED INGREDIENTS")
                        })
                            .catch(error => {
                                console.log(error)
                                setGlobalState("error", "Error", "Failed to save ingredients in this product.", true)
                            })
                            .finally(() => {
                                setIsTableLoading(false);
                            });

                    })
                    setGlobalState("success", "Success", "Successfully added new product.", true)

                })
                .catch(error => {
                    console.log(error)

                    setGlobalState("error", "Error", "Failed to save new product.", true)
                })
                .finally(() => {
                    setIsTableLoading(false);
                });

        }
    }


    const handleDeleteClick = (row) => {
        let tempList = listOfIngredients;
        let tempQTY = qty;
        let tempSum = sum;

        tempList.splice(row.index, 1);
        tempQTY.splice(row.index, 1)
        tempSum = parseFloat(sum) - (parseFloat(row.data.totalCost) * parseFloat(row.data.quantity));
        setListOfIngredients(tempList)
        setQty(tempQTY)
        setSum(tempSum)
        setIsCompute(true)
        if (isEdit === "true") {
            setDeletedIngredients([...deletedIngredients, row.data.id])
        }


    }

    return (
        <>
            {activeStep === 0 ?
                <div
                    style={{
                        width: "100%",
                        overflowY: "auto",
                        backgroundColor: ConstantStyles.white,
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingBottom: "30px",
                        borderRadius: ConstantStyles["default-border-radius"],
                        position: "relative"
                    }}
                >
                    <br />
                    <p className="text-header">New Product</p>
                    <hr />
                    <form onSubmit={handleSubmit}>
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={7} sm={7} md={3} lg={3}>
                                    <p style={{ marginBottom: "3px" }}>Product Name <span style={{ color: ConstantStyles.error }}>*</span></p>
                                    <CustomTextField
                                        value={name}
                                        onChange={setName}
                                        required={true}
                                        placeholder="Chocolate Cookies"
                                    />
                                </Grid>
                                <Grid item xs={5} sm={5} md={2} lg={2}>
                                    <p style={{ marginBottom: "3px" }}>Selling UoM<span style={{ color: ConstantStyles.error }}>*</span></p>
                                    <CustomTextField
                                        value={UoM}
                                        onChange={setUoM}
                                        placeholder="1 - box"
                                        required={true}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={1} lg={1} />
                                {/* <Grid item xs={12} sm={12} md={5} lg={5}>
                                <p style={{ marginBottom: "3px" }}>
                                    Total Selling Price <CustomTooltip title="Total Selling Price = Selling Price - Shipping Cost" icon="help"/>
                                </p>
                                <CustomTextField
                                    value={(parseFloat(sellingPrice) + parseFloat(shippingCost))}
                                    disabled={true}
                                />
                            </Grid> */}
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                    <p style={{ marginBottom: "3px" }}>Selling Price<span style={{ color: ConstantStyles.error }}>*</span></p>
                                    <CustomTextField
                                        value={sellingPrice}
                                        onChange={setSellingPrice}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={1} lg={1} />
                                {/* <Grid item xs={12} sm={12} md={5} lg={5}>
                                <p style={{ marginBottom: "3px" }}>Total Cost: <CustomTooltip title="Total Cost = Cost of all ingredients" icon="help"/></p>
                                <CustomTextField
                                    value={parseFloat(sum)}
                                    disabled={true}
                                />
                            </Grid> */}
                            </Grid>
                            {/* <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                    <p style={{ marginBottom: "3px" }}>Shipping Cost <CustomTooltip title="Shipping Cost for the merchant to deliver to the customer" icon="help" /></p>
                                    <CustomTextField
                                        value={shippingCost}
                                        onChange={setShippingCost}
                                        placeholder={"Shipping Cost"}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={1} lg={1} /> */}
                            {/* <Grid item xs={12} sm={12} md={5} lg={5}>
                                <p style={{ marginBottom: "3px" }}>Contribution Margin <CustomTooltip title="Contribution Margin = Selling Price - Total Cost" icon="help"/></p>
                                <CustomTextField
                                    value={parseFloat(sellingPrice) - parseFloat(sum)}
                                    required={true}
                                    disabled={true}
                                />
                            </Grid> */}

                            {/* </Grid> */}
                            {/* <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                                <p style={{ marginBottom: "3px" }}>Cost per UoM <CustomTooltip title="Cost per UoM = Total Cost / Quantity per UoM" icon="help"/></p>
                                <CustomTextField
                                    value={isNaN(sum / qtyPerUoM) ? "-" : (sum / qtyPerUoM)}
                                    placeholder={"UoM"}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid> */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                    <p style={{ marginBottom: "3px" }}>Quantity per UoM <span style={{ color: ConstantStyles.error }}>*</span> <CustomTooltip title="Quantity per UoM is how many items are in the current UoM" icon="help" /></p>
                                    <CustomTextField
                                        value={qtyPerUoM}
                                        onChange={setQtyPerUoM}
                                        placeholder={"UoM"}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        {/* {isSubmit && <CustomCircularProgress width={"100%"} height={"100%"} size={36} customStyle={{ position: "absolute", top: 0, left: 0, backgroundColor: "rgba(0, 0, 0, 0.2)" }} />} */}
                    </form>
                </div>

                : null}
            {activeStep === 1 ?
                <div
                    style={{
                        width: "100%",
                        minHeight: "50vh",
                        overflowY: "auto",
                        backgroundColor: ConstantStyles.white,
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingBottom: "30px",
                        borderRadius: ConstantStyles["default-border-radius"],
                        position: "relative"
                    }}
                >
                    <br />
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ marginTop: "3px" }} className="text-header">
                            Ingredients {"   "} for {name}
                            <CustomButton
                                buttonLabel={"Add New Ingredient"}
                                onClick={handleOpen}
                            />
                            <Modal
                                open={open}
                                onClose={() => handleClose({ submitted: false })}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <CreateIngredient
                                    isEdit={false}
                                    fromProductPage={true}
                                    handleClose={handleClose}
                                />
                            </Modal>

                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3} style={{ marginTop: "3px" }}>
                        </Grid>

                    </Grid>
                    {/* <p className="text-header">Ingredients</p> */}
                    <hr />
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} md={3} lg={3} style={{ marginTop: "3px" }}>
                            <CustomAutocompleteSelect
                                placeholder="Add Existing Ingredient"
                                options={allIngredients}
                                value={ingredient}
                                onChange={(value) => addIngredients(value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ marginTop: "3px" }}>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} style={{ marginTop: "3px" }}>
                            <strong> Running Total: {formatNumberRoundOffWithPeso(parseFloat(sum), 2)} </strong>
                        </Grid>

                    </Grid>
                    {listOfIngredients.map((ing, i) => {
                        return (
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6} md={3} lg={3} style={{ marginTop: "3px" }}>
                                        {i === 0 ? <p style={{ marginBottom: "3px" }}>Ingredient Name</p> : <p style={{ marginBottom: "3px" }}></p>}
                                        <CustomTextField
                                            value={ing.name}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={2} lg={2} style={{ marginTop: "3px" }}>
                                        {i === 0 ? <p style={{ marginBottom: "3px" }}>Quantity <CustomTooltip title="Quantity need to be used in the product dependent on the UoM (e.g., 1.5, 0.5, 1)" icon="help" /> </p> : <p style={{ marginBottom: "3px" }}></p>}
                                        <CustomTextField
                                            value={qty[i]}
                                            onChange={(value) => {

                                                let tempList = listOfIngredients;
                                                let tempQTY = qty;
                                                let tempSum = 0;
                                                tempQTY[i] = value
                                                tempList[i]['quantity'] = value
                                                tempList.forEach(element => {
                                                    tempSum += element.totalCost * parseFloat(element.quantity);
                                                });
                                                setListOfIngredients(tempList)
                                                setQty(tempQTY)
                                                setSum(parseFloat(tempSum))
                                                setRefresh(!refresh)
                                                setIsCompute(true)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={1} lg={1} style={{ marginTop: "3px" }}>
                                        {i === 0 ? <p style={{ marginBottom: "3px" }}>UoM <CustomTooltip title="UoM in the ingredients database" icon="help" /></p> : <p style={{ marginBottom: "3px" }}></p>}
                                        <CustomTextField
                                            value={ing.unit}
                                            // value={ing.cost.toString() + "/" + ing.unit}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={2} lg={2} style={{ marginTop: "3px" }}>
                                        {i === 0 ? <p style={{ marginBottom: "3px" }}>Recipe % <CustomTooltip title="Recipe % is the percentage cost of the ingredient against total variable cost" icon="help" /> </p> : <p style={{ marginBottom: "3px" }}></p>}
                                        <CustomTextField
                                            value={ing.percentage ? ing.percentage.toString() : "%"}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={3} lg={3} style={{ marginTop: "3px" }}>
                                        <strong>
                                            {i === 0 ? <p style={{ marginBottom: "3px" }}>Total Variable Cost <CustomTooltip title="Total Variable Cost = Cost Per UoM * Quantity" icon="help" /> </p> : <p style={{ marginBottom: "3px" }}></p>}
                                            <CustomTextField
                                                value={parseFloat(ing.cost) * parseFloat(ing.quantity)}
                                                variant="filled"
                                                color="#ADD8E6"
                                            />
                                        </strong>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={1} lg={1} style={{ marginTop: "3px" }}>
                                        {i === 0 ? <p style={{ marginBottom: "3px" }}>Action</p> : <p style={{ marginBottom: "3px" }}></p>}
                                        <button
                                            id={i + "delete"}
                                            className='btn btn-danger ml-2 p-2 pt-0 pb-0'
                                            onClick={() => handleDeleteClick({ index: i, data: ing })}

                                        >
                                            <DeleteIcon />
                                        </button>
                                    </Grid>
                                </Grid>
                                {i !== listOfIngredients.length - 1 ? <hr /> : null}
                            </>
                        )
                    })}
                </div>

                : null}

            {activeStep === 2 ?
                <>
                    <div
                        style={{
                            width: "100%",
                            overflowY: "auto",
                            backgroundColor: ConstantStyles.white,
                            paddingLeft: "30px",
                            paddingBottom: "30px",
                            paddingRight: "30px",
                            borderRadius: ConstantStyles["default-border-radius"],
                            position: "relative"
                        }}
                    >
                        <br />
                        <p className="text-header">Product</p>
                        <hr />
                        <form onSubmit={handleSubmit}>
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={7} sm={7} md={3} lg={3}>
                                        <p style={{ marginBottom: "3px" }}>Product Name </p>
                                        {name}
                                    </Grid>
                                    <Grid item xs={5} sm={5} md={2} lg={2}>
                                        <p style={{ marginBottom: "3px" }}>Selling UoM</p>
                                        {UoM}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={1} lg={1} />
                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                        <strong>
                                            <p style={{ marginBottom: "3px" }}>
                                                Selling Price
                                            </p>
                                            {formatNumberWCommaRoundOff(parseFloat(sellingPrice) + parseFloat(shippingCost), 2)}
                                        </strong>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                        <p style={{ marginBottom: "3px" }}>Selling Price</p>
                                        {formatNumberWCommaRoundOff(sellingPrice, 2)}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1} lg={1} />
                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                        <strong>
                                            <p style={{ marginBottom: "3px" }}>Total Variable Cost: <CustomTooltip title="Total Variable Cost = Cost of all ingredients" icon="help" /></p>
                                            {formatNumberWCommaRoundOff(parseFloat(sum), 2)}
                                        </strong>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                        {/* <p style={{ marginBottom: "3px" }}>Shipping Cost <CustomTooltip title="Shipping Cost for the merchant to deliver to the customer" icon="help" /></p>
                                        {shippingCost} */}
                                        <p style={{ marginBottom: "3px" }}>Cost per UoM <CustomTooltip title="Cost per UoM = Total Variable Cost / Quantity per UoM" icon="help" /></p>
                                        {isNaN(sum / qtyPerUoM) ? "-" : (sum / qtyPerUoM)}

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1} lg={1} />
                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                        <b>
                                            <p style={{ marginBottom: "3px" }}>Contribution Margin <CustomTooltip title="Contribution Margin = Selling Price - Total Variable Cost" icon="help" /></p>
                                            {formatNumberWCommaRoundOff(parseFloat(sellingPrice) - parseFloat(sum), 2)}
                                        </b>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={5} lg={5}>


                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                        <p style={{ marginBottom: "3px" }}>Quantity per UoM <span style={{ color: ConstantStyles.error }}>*</span> <CustomTooltip title="Quantity per UoM is how many items are in the current UoM" icon="help" /></p>
                                        {qtyPerUoM}
                                    </Grid>
                                </Grid>
                            </div>
                            {/* {isSubmit && <CustomCircularProgress width={"100%"} height={"100%"} size={36} customStyle={{ position: "absolute", top: 0, left: 0, backgroundColor: "rgba(0, 0, 0, 0.2)" }} />} */}
                        </form>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            minHeight: "50vh",
                            overflowY: "auto",
                            backgroundColor: ConstantStyles.white,
                            paddingLeft: "30px",
                            paddingRight: "30px",
                            paddingBottom: "30px",
                            borderRadius: ConstantStyles["default-border-radius"],
                            position: "relative"
                        }}
                    >
                        <br />
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginTop: "3px" }} className="text-header">
                                Ingredients {"   "}
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3} style={{ marginTop: "3px" }}>
                            </Grid>

                        </Grid>
                        {/* <p className="text-header">Ingredients</p> */}
                        <hr />
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={3} lg={3} style={{ marginTop: "3px" }}>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} style={{ marginTop: "3px" }}>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} style={{ marginTop: "3px" }}>
                                <strong> Running Total: {formatNumberRoundOffWithPeso(parseFloat(sum), 2)} </strong>
                            </Grid>

                        </Grid>
                        {listOfIngredients.map((ing, i) => {
                            return (
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={3} lg={3} style={{ marginTop: "3px" }}>
                                            {i === 0 ? <p style={{ marginBottom: "3px" }}>Ingredient Name</p> : <p style={{ marginBottom: "3px" }}></p>}
                                            {ing.name}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={2} lg={2} style={{ marginTop: "3px" }}>
                                            {i === 0 ? <p style={{ marginBottom: "3px" }}>Quantity <CustomTooltip title="Quantity need to be used in the product dependent on the UoM (e.g., 1.5, 0.5, 1)" icon="help" /> </p> : <p style={{ marginBottom: "3px" }}></p>}
                                            {formatNumberWCommaRoundOff(qty[i], 2)}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={2} lg={2} style={{ marginTop: "3px" }}>
                                            {i === 0 ? <p style={{ marginBottom: "3px" }}>Cost per UoM <CustomTooltip title="Cost and UoM in the ingredients database" icon="help" /></p> : <p style={{ marginBottom: "3px" }}></p>}
                                            {ing.cost.toString() + "/" + ing.unit}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={2} lg={2} style={{ marginTop: "3px" }}>
                                            {i === 0 ? <p style={{ marginBottom: "3px" }}>Recipe % <CustomTooltip title="Recipe % is the percentage cost of the ingredient against total variable cost" icon="help" /> </p> : <p style={{ marginBottom: "3px" }}></p>}
                                            {ing.percentage ? ing.percentage.toString() : "%"}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={2} lg={2} style={{ marginTop: "3px" }}>
                                            {i === 0 ? <p style={{ marginBottom: "3px" }}>Total Variable Cost <CustomTooltip title="Total Variable Cost = Cost Per UoM * Quantity" icon="help" /> </p> : <p style={{ marginBottom: "3px" }}></p>}
                                            {formatNumberWCommaRoundOff(parseFloat(ing.cost) * parseFloat(ing.quantity), 2)}
                                        </Grid>
                                    </Grid>
                                    {i !== listOfIngredients.length - 1 ? <hr /> : null}

                                </>
                            )
                        })}
                    </div>
                </>
                : null}



            <br />

            {activeStep === 0 ?
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} md={11} lg={11} style={{ marginTop: "3px" }}>
                        {parseInt(window.localStorage.getItem("productsLength")) >= 3 && window.localStorage.getItem("premium") === "false" ?
                            <p><i>Only 3 products can be added to trial accounts </i></p>
                            :
                            null}
                        <CustomButton
                            buttonLabel={"Next"}
                            onClick={() => {
                                if (name === "" || sellingPrice <= 0 || shippingCost < 0 || UoM === "" || qtyPerUoM < 0) {
                                    setGlobalState("error", "Error", "Please fill up Required fields", true)
                                }
                                else
                                    setActiveStep(activeStep + 1)

                            }}
                            customSX={{ "marginLeft": '20px', "minWidth": "100px" }}

                            disabled={parseInt(window.localStorage.getItem("productsLength")) >= 3 && window.localStorage.getItem("premium") === "false"}
                        />
                    </Grid>
                </Grid>
                : null}

            {activeStep === 1 ?
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} md={11} lg={11} style={{ marginTop: "3px" }}>
                        <CustomButton
                            buttonLabel={"Back"}
                            onClick={() => { setActiveStep(activeStep - 1) }}
                            disabled={activeStep === 0 ? true : false}
                            customSX={{ minWidth: "100px", backgroundColor: "gray" }}
                        />

                        <CustomButton
                            buttonLabel={"Save"}
                            onClick={() => {
                                handleSubmit()
                                setActiveStep(activeStep + 1)
                            }}

                            customSX={{ "marginLeft": '20px', minWidth: "100px" }}
                        />
                    </Grid>
                </Grid>
                : null}
            {activeStep === 2 ?
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} md={11} lg={11} style={{ marginTop: "3px" }}>
                        <CustomButton
                            buttonLabel={"List of Products"}
                            onClick={() => { navigate("/products") }}
                            customSX={{ "marginLeft": '20px', minWidth: "100px" }}
                        />
                    </Grid>
                </Grid>
                : null}

        </>
    );
}

export default CreateProduct;