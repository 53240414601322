import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import {
    Modal,
    Box
} from "@mui/material";
import DashboardModal from "./dashboardModal";
import CustomTooltip from "../../shared/CustomTooltip";


function Dashboard() {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([])
    const [refresh, setRefresh] = useState(true)
    let title = {
        'Contribution Margin Calculator': ["Enter New Product", "Edit Existing Product"],
        'Reports': ["Products Contribution Margin", "Audit Trail"],
        "Breakeven Point Coming Soon...": ["Coming Soon"],
        "Go Premium": ["Payment"]
    }

    const handleClose = () => {
        setOpen(false)
    };


    return (
        <>
            <div style={{
                display: "flex", flexDirection: window.innerWidth > 600 ? "row" : "column",
            }}>
                {Object.keys(title).map(key => {
                    return (
                        <>
                            <Box
                                sx={{
                                    width: 150,
                                    height: 150,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    marginLeft: window.innerWidth > 600 ? "3%" : "1%",
                                    marginBottom: "3%",
                                    borderRadius: "10px 10px 10px 10px",
                                    backgroundColor: 'primary.dark',
                                    '&:hover': {
                                        backgroundColor: 'primary.main',
                                        opacity: [0.9, 0.8, 0.7],
                                    },
                                }}
                                onClick={() => {
                                    setOpen(true)
                                    setSelected(title[key])
                                    setRefresh(!refresh)
                                }}
                            >
                                <span style={{
                                    color: "white",
                                    textAlign: "center",
                                }}> {key}
                                    {key === "Contribution Margin Calculator" ?
                                        <CustomTooltip color="white" title="Selling price less variable cost per unit. Variable costs pertain to costs that vary with the level of the product output or product sales. The fixed cost of your business is not considered yet in the computation." icon="help" />
                                        : null}
                                </span>
                            </Box>
                        </>
                    )
                })}
            </div>
            <div style={{
                backgroundColor: "white",
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <DashboardModal
                        handleClose={handleClose}
                        selected={selected}
                    />
                </Modal>

            </div>
        </>
    )


}


export default Dashboard;