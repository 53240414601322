import { useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContext,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import CustomButton from "./CustomButton"

import GlobalStates from '../../utils/GlobalStates';

function CustomConfirmation() {
    const globalStates = useHookState(GlobalStates);

    const handleClose = (action) => {
        globalStates.showConfirmation.set(false)
        if (action === "Confirm") {
            globalStates.isConfirmed.set(true)
        }
    };

    return (
        <div>
            <Dialog
//                open={globalStates.showConfirmationModal.get()}
                maxWidth={"xs"}
                open={globalStates.showConfirmation.get()}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {globalStates.confirmationTitle.get()}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {globalStates.confirmationBody.get()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CustomButton
                        onClick={() => handleClose("Cancel")}
                        buttonLabel={"Cancel"}
                    />
                    <CustomButton
                        onClick={() => handleClose("Confirm")}
                        buttonColor="primary"
                        buttonLabel={"Confirm"}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CustomConfirmation;