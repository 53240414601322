/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : Historical Data
 * File Directory           : src/components/shared/DateFilter
 * Module                   : DateFilter
 * Author                   : Ava
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : *currently for all tables to be filtered*
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Historical Data        -   component for Historical Data for listing items
 *
 * #How to call:
        <DateFilter 
            //props send useState = both value and setter
            dateRange={
                {
                    startDate:moment().clone().startOf('month'), //chosen start date , default- start date of current month
                    endDate:moment().clone().endOf('month') //chosen end date , default- end date of next month
                }
            },
            setDateRange
        />
 *******************************************************************************************/
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

const DateFilter = (props) => {
    // const [startDate, setStartDate] = useState(moment().clone().startOf('month'));//Default start date 
    // const [endDate, setEndDate] = useState( moment().clone().startOf('month'))   ;//Default end date
    const [startDate, setStartDate] = useState( moment(props.startDate, "MM-DD-YYYY"));//Default start date 
    const [endDate, setEndDate] = useState(  moment(props.endDate, "MM-DD-YYYY"))   ;//Default end date
    
    const [focusedInput, setFocusedInput] = useState();//Default focused field either [startDate/endDate]
    const [closed, setClosed] = useState(false);//Default focused field either [startDate/endDate]
    const [dateRange, setDateRange] = useState()
    const onClose = () => {
        setTimeout(() => {
            // console.log(dateRange)
            // console.log(endDate)
            if (endDate === null) {
                setStartDate(startDate);setEndDate(startDate );
                setDateRange({startDate:startDate,endDate:startDate})
            }
        }, 2000);
    }

//    console.log("DATE FILTER", props.startDate, props.endDate, startDate,endDate)
    return (
        <div>
            <DateRangePicker
                startDate={startDate} //set default selected start date
                endDate={endDate} //set default selected end date
                startDateId="defStartDate" //idk just required
                endDateId="defEndDate" //idk just required
                enableOutsideDays={true} //enable past days
                isOutsideRange={() => false} //enable past days
                onClose={onClose}
                numberOfMonths= {2} //number of months listed
                onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);setEndDate(endDate );
                    setDateRange({startDate:startDate,endDate:endDate})
                }} //onDateSelect save dates
                focusedInput={focusedInput}//setFocus on input field either startDate or endDate
                onFocusChange={focusedInput => setFocusedInput(focusedInput)} //set focus
            />

        </div>
    );
};

export default DateFilter;