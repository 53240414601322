import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from "prop-types";

function CustomTooltip(props) {

    return (
        <Tooltip title={props.title} placement="top">
            {props.icon === "help" ?
                <HelpIcon color={props.color} fontSize="small" style={{ paddingBottom: "5px" }} /> :
                null}
        </Tooltip>
    );

}

CustomTooltip.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
}

CustomTooltip.defaultProps = {
    title: "Add Tooltip Message",
    icon: "help",
    color: "action"
}
export default CustomTooltip;


