import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import { Grid } from "@mui/material";
import ConstantStyles from "../../../styles/ConstantStyles.json";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CustomTextField from "../../shared/CustomTextField";
import CustomButton from "../../shared/CustomButton";
import GlobalStates from "../../../utils/GlobalStates";
import api from "../../../utils/Endpoints";


function CreateIngredient(props) {
    const globalStates = useHookState(GlobalStates);

    const [username, setUsername] = useState("");
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isResetting, setIsResetting] = useState(false)
    const [isConfirming, setIsConfirming] = useState(true);
    const [OTP, setOTP] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;


    const setGlobalState = (alerType, alertTitle, alertBody, showAlert) => {
        globalStates.alertType.set(alerType);
        globalStates.alertTitle.set(alertTitle);
        globalStates.alertBody.set(alertBody);
        globalStates.showAlert.set(showAlert);

        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 3000);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoggingIn(true);
        let body = {
            username: username
        }
        api.auth.get_reset(body).then(response => {
            setGlobalState("success", "Success", "Please check your email for your OTP", true);
            setIsConfirming(false);
        })
            .catch(error => {
                console.log(error);
                setGlobalState("error", "Error", "Please contact us on Facebook for help. Thank you", true);
            })

    }

    const handleConfirm = (e) => {
        e.preventDefault();
        setIsResetting(true)

        let body = {
            username: username,
            email: username,
            password: newPassword,
            confirmation_code: OTP
        }
        if (passw.test(newPassword)) {
            api.auth.post_reset(body).then(response => {
                setGlobalState("success", "Success", "Password Updated Successfully!", true);
                setTimeout(() => {
                    globalStates.showAlert.set(false);
                    window.location.href = "/";
                }, 3000);

            })
                .catch(error => {
                    console.log(error);
                    setIsResetting(false)
                    setGlobalState("error", "Error", "Please contact us on Facebook for help. Thank you", true);
                })
        }
        else {
            setGlobalState("error", "Error", "Password should contain at least 1 number, 1 special character, 1 uppercase, 1 lowercase and minimum of 8 characters to ensure that our password is safe.", true);
            setIsResetting(false)

        }

    }

    return (
        <>
            <div
                style={{
                    width: window.innerWidth > 600 ? "50%" : "80%",
                    height: window.innerWidth > 600 ? "50%" : "60%",
                    overflowY: "auto",
                    backgroundColor: ConstantStyles.white,
                    borderRadius: ConstantStyles["default-border-radius"],
                    marginTop: "10%",
                    marginLeft: window.innerWidth > 600 ? "25%" : "10%",
                    position: "relative",
                }}
            >
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={1} sm={1} md={1} lg={1} style={{ marginBottom: "3%" }} />

                    <Grid item xs={10} sm={10} md={10} lg={10} className="text-header" style={{ marginBottom: "3%" }}>
                        Forgot Password?
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} style={{ marginBottom: "3%" }}>
                        <IconButton
                            aria-label="close"
                            onClick={() => props.handleClose()}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {isConfirming ?
                    <Grid container spacing={2}>
                        <Grid item md={3} lg={3} sm={3} xs={3} />
                        <Grid item xs={10} sm={10} md={6} lg={6}>
                            <CustomTextField
                                value={username}
                                onChange={setUsername}
                                placeholder={"Enter Email"}
                                type="text"
                                id="email"
                                disabled={isLoggingIn}
                                customSX={{
                                    padding: "12px 20px",
                                    marginBottom: "10px"
                                }}
                            />
                        </Grid>
                        <Grid item md={3} lg={3} sm={3} xs={3} />

                        <Grid item md={3} lg={3} sm={1} xs={1} />
                        <Grid item md={6} lg={6} xs={10} sm={10} >
                            <CustomButton
                                buttonLabel={isLoggingIn ? "Sending Password..." : "Reset Password"}
                                onClick={handleSubmit}
                                type="submit"
                                disabled={isLoggingIn}
                                customSX={{
                                    width: "100%",
                                    padding: "12px 20px"
                                }}
                            />
                        </Grid>
                        <Grid item md={3} lg={3} sm={1} xs={1} />

                    </Grid>
                    :
                    <Grid container spacing={2}>
                        <Grid item md={1} lg={1} sm={1} xs={1} />
                        <Grid item md={5} lg={5} xs={10} sm={10} >
                            <CustomTextField
                                value={username}
                                onChange={setUsername}
                                placeholder={"Enter Email"}
                                type="text"
                                id="email"
                                disabled={isResetting}
                                customSX={{
                                    padding: "12px 20px",
                                    marginBottom: "10px"
                                }}
                            />
                        </Grid>
                        <Grid item md={5} lg={5} xs={10} sm={10} >
                            <CustomTextField
                                value={newPassword}
                                onChange={setNewPassword}
                                placeholder={"Enter Password"}
                                type="password"
                                id="password"
                                disabled={isResetting}
                                customSX={{
                                    padding: "12px 20px",
                                    marginBottom: "10px"
                                }}
                            />
                        </Grid>
                        <Grid item md={1} lg={1} sm={1} xs={1} />

                        <Grid item md={1} lg={1} sm={1} xs={1} />
                        <Grid item md={5} lg={5} xs={10} sm={10} >
                            <CustomTextField
                                value={OTP}
                                onChange={setOTP}
                                placeholder={"Enter OTP"}
                                type="text"
                                id="OTP"
                                disabled={isResetting}
                                customSX={{
                                    padding: "12px 20px",
                                    marginBottom: "10px"
                                }}
                            />
                        </Grid>
                        <Grid item md={6} lg={6} />


                        <Grid item md={3} lg={3} sm={1} xs={1} />
                        <Grid item md={6} lg={6} xs={10} sm={10} >
                            <CustomButton
                                buttonLabel={isResetting ? "Resetting Password..." : "Confirm New Password"}
                                onClick={handleConfirm}
                                type="submit"
                                disabled={isResetting}
                                customSX={{
                                    width: "100%",
                                    padding: "12px 20px"
                                }}
                            />
                        </Grid>
                        <Grid item md={3} lg={3} sm={1} xs={1} />

                    </Grid>

                }


                <br />
                <br />
            </div>
        </>
    );
}

export default CreateIngredient;