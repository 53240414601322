/*******************************************************************************************
//  * File Name                : SideBar
//  * File Directory           : src/components/general/SideBar
//  * Module                   : SideBar
//  * Author                   : Ava
//  * Version                  : 1
//  * Purpose                  : general
//  * Used In				   : Home 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   SideBar        -   
 *
 * #How to call:
 *    <SideBar/>
 *
 *******************************************************************************************/
import { useState, useEffect } from 'react';
import { useState as useHookState } from "@hookstate/core";
import { Link } from "react-router-dom";
import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarHeader,
    SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import PropTypes from "prop-types";
import SidebarLinksMobile from "../../utils/SidebarLinksMobile.json"
import ConstantStyles from "../../styles/ConstantStyles.json";
import {
    Tooltip
} from "@mui/material";
import GlobalStates from '../../utils/GlobalStates';
import SiteLogo from '../../assets/img/Picture2.png'
//style
const style = {
    sliderHeader: {
        padding: '15px 10px 15px 20px',
        textTransform: 'none',
        fontWeight: '600',
        fontSize: 18,
        letterSpacing: '1px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: ConstantStyles.white
    },
    imgLink: {
        width: "20px",
        filter: "invert(1)"
    },
    imgSize: {
        height: "38px"
    },
    linkActive: {
        backgroundColor: "#e9ecef40"
    },
    links: {
        textDecoration: "none"
    },
    activeFont: {
        fontWeight: "400",
        color: "#fff",
        textDecoration: "none"
    }
}

const SideBar = ({
    collapsed,
    handleToggleSidebar }) => {
    const globalStates = useHookState(GlobalStates);
    const [activeLink, setLinkActive] = useState(window.location.pathname);

    useEffect(() => {
        globalStates.showFilterSidebar.set(false);
    }, [window.location.pathname]);

    //function toggle collapse
    const handleCollapsedChange = (obj) => {
        // obj = nav name
        document.getElementById("collapseBtn").click(); // trigger to click the burger / menu button
        setTimeout(() => {
            let id = "#sub_" + obj; // id of the button
            let i = 0;
            const btnClicked = document.querySelectorAll(id + " > .pro-inner-item") // select button (child element of the id)
            for (i; i < btnClicked.length; i++) {
                btnClicked[i].click(); // trigger to click the child element to show sub categories
            }
        }, 250);
    }
    // nav menu mapping
    const renderedNav = SidebarLinksMobile.map((nav, index) => {

        // // submenu mapping
        // const renderedSubNav = nav.subNav.map((subNav, index) => {
        //     return (
        //         <MenuItem key={index}>
        //             <Link to={subNav.pageUrl}
        //                 style={activeLink.includes(subNav.pageUrl) ? style.activeFont : style.links}
        //                 onClick={() => {
        //                     setLinkActive(subNav.pageUrl)
        //                 }}
        //             >{subNav.pageTitle}</Link>
        //         </MenuItem>
        //     )
        // })

        if (nav.type === "sub" && !collapsed) {
            return (
                <SubMenu
                    id={`sub_${nav.navTitle}`}
                    key={index}
                    title={nav.navTitle}
                    icon={<img alt={nav.navTitle} style={style.imgLink} src={nav.myIcon} />} >
                </SubMenu>
            )
        }
        else {
            return (
                <Tooltip key={index} title={collapsed ? nav.navTitle : ""} placement="right" arrow>
                    {nav.type === "main" ?
                        <MenuItem style={activeLink.includes(nav.navUrl) ? style.linkActive : style.links}
                            icon={<img alt={nav.navTitle} style={style.imgLink} src={nav.myIcon} />} >
                            <Link to={nav.navUrl}
                                style={activeLink.includes(nav.navUrl) ? style.activeFont : style.links}
                                onClick={() => {
                                    setLinkActive(nav.navUrl)
                                }}
                            >
                                {nav.navTitle}
                            </Link>

                        </MenuItem>
                        :
                        <MenuItem
                            onClick={() => {
                                handleCollapsedChange(nav.navTitle)
                            }}
                            id={nav.navTitle}
                            icon={<img alt={nav.navTitle} style={style.imgLink} src={nav.myIcon} />} >
                            <Link to={nav.navUrl}
                                onClick={() => {
                                    setLinkActive(nav.navUrl)
                                }}
                            >
                                <span
                                    style={{ textDecoration: "none" }}>
                                    {nav.navTitle}
                                </span>
                            </Link>
                        </MenuItem>
                    }
                </Tooltip>
            )
        }
    });

    //main content
    return (
        <ProSidebar
            collapsed={collapsed}
            className="sideBarFix"
            breakPoint="sm"
            onToggle={handleToggleSidebar}
        >
            <SidebarHeader>

                <Link to={"/"}>
                    <div
                        className={`siteIcon ${collapsed ? "flex-center" : "flex-justify-start"}`}
                        style={style.sliderHeader}
                    >
                        <img style={style.imgSize} src={SiteLogo} />
                        
                        {!collapsed && <p style={{ color: "#F3C100" }}></p>}
                    </div>
                </Link>
            </SidebarHeader>
            <ProSidebar
                className="sideBarWidth"
            >
                <SidebarContent>
                    <Menu iconShape="circle">
                        {renderedNav}
                    </Menu>
                </SidebarContent>
            </ProSidebar>
        </ProSidebar>
    );
};

SideBar.propTypes = {
    collapsed: PropTypes.bool,
    handleToggleSidebar: PropTypes.func
};

SideBar.defaultProps = {
    collapsed: false,
    handleToggleSidebar: () => { },
};
export default SideBar;