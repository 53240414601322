import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import moment from "moment";
import { Grid } from "@mui/material";
import CustomTextField from "../../shared/CustomTextField";
import CustomButton from "../../shared/CustomButton";
import CustomDateTimePicker from "../../shared/CustomDateTimePicker";
import CustomCircularProgress from "../../shared/CustomCircularProgress";
import ConstantStyles from "../../../styles/ConstantStyles.json";
import { formatNumberRoundOffWithPeso } from "../../../utils/Formats";
import api from "../../../utils/Endpoints";
import GlobalStates from '../../../utils/GlobalStates';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

function CreateIngredient(props) {
    const globalStates = useHookState(GlobalStates);

    const [name, setName] = useState("");
    const [purchasingPrice, setPurchasingPrice] = useState(0);
    const [UoM, setUoM] = useState("");
    const [shippingCost, setShippingCost] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false);


    useEffect(() => {
        if (props.isEdit === true) {
            setName(props.name)
            setPurchasingPrice(props.purchasingPrice)
            setUoM(props.UoM)
            setShippingCost(props.shippingCost)
        }
    }, [props])

    const setGlobalState = (alerType, alertTitle, alertBody, showAlert) => {
        globalStates.alertType.set(alerType);
        globalStates.alertTitle.set(alertTitle);
        globalStates.alertBody.set(alertBody);
        globalStates.showAlert.set(showAlert);

        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 3000);
    }

    const handleSubmit = (e) => {

        if (name !== "" && purchasingPrice > 0 && UoM !== "") {
            let body = {
                name: name,
                purchasing_price: parseFloat(purchasingPrice),
                shipping_cost: parseFloat(shippingCost),
                unit_of_measure: UoM
            }

            if (props.isEdit === false) {
                api.ingredients.
                    post(body)
                    .then(response => {
                        setGlobalState("success", "Success", "Successfully added a new ingredient", true)
                        if (props.fromProductPage === false)
                            props.handleClose()
                        else
                            props.handleClose({ submitted: true, body: body })
                    })
                    .catch(error => {
                        setGlobalState("error", "Error", "Failed to create new ingredient", true)
                    })
            }
            else if (props.isEdit === true) {
                api.ingredients.
                    put(props.id, body)
                    .then(response => {
                        setGlobalState("success", "Success", "Successfully edited an ingredient", true)
                        props.handleClose()
                    })
                    .catch(error => {
                        setGlobalState("error", "Error", "Failed to create edit ingredient", true)
                    })
            }
        }
        else {
            setGlobalState("error", "Error", "Please answer all required fields", true)
        }

    }
    return (
        <>
            <div
                style={{
                    width: window.innerWidth > 600 ? "50%" : "80%",
                    height: window.innerWidth > 600 ? "70%" : "60%",
                    overflowY: "auto",
                    backgroundColor: ConstantStyles.white,
                    paddingLeft: "30px",
                    paddingRight: window.innerWidth >600 ? "0px" : "30px",
                    borderRadius: ConstantStyles["default-border-radius"],
                    marginTop: "5%",
                    marginLeft: window.innerWidth > 600 ? "25%" : "10%",
                    position: "relative"
                }}
            >
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={11} sm={11} md={11} lg={11} className="text-header">
                        New Ingredient
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <IconButton
                            aria-label="close"
                            onClick={() => props.handleClose()}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <hr />
                <form onSubmit={handleSubmit}>
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <p style={{ marginBottom: "3px" }}>Ingredient Name:<span style={{ color: ConstantStyles.error }}>*</span></p>
                                <CustomTextField
                                    value={name}
                                    onChange={setName}
                                    placeholder={"Sugar"}
                                    required={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <p style={{ marginBottom: "3px" }}>Purchasing Price<span style={{ color: ConstantStyles.error }}>*</span></p>
                                <CustomTextField
                                    value={purchasingPrice}
                                    onChange={setPurchasingPrice}
                                    placeholder={"Purchasing Price"}
                                    required={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <p style={{ marginBottom: "3px" }}>Shipping Cost</p>
                                <CustomTextField
                                    value={shippingCost}
                                    onChange={setShippingCost}
                                    placeholder={"Shipping Cost"}
                                    required={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <p style={{ marginBottom: "3px" }}>Unit of Measure (UoM)<span style={{ color: ConstantStyles.error }}>*</span></p>
                                <CustomTextField
                                    value={UoM}
                                    onChange={setUoM}
                                    placeholder={"KG"}
                                    required={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <p style={{ marginBottom: "3px" }}>Cost per UoM</p>
                                <CustomTextField
                                    value={parseFloat(shippingCost) + parseFloat(purchasingPrice)}
                                    placeholder={"UoM"}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={11} lg={11} style={{ marginTop: "3px" }}>
                                <CustomButton
                                    buttonLabel={"Submit"}
                                    onClick={() => { handleSubmit() }}
                                    customSX={{ minWidth: "100px" }}
                                />
                            </Grid>
                        </Grid>

                    </div>
                    {isSubmit && <CustomCircularProgress width={"100%"} height={"100%"} size={36} customStyle={{ position: "absolute", top: 0, left: 0, backgroundColor: "rgba(0, 0, 0, 0.2)" }} />}
                </form>

            </div>

            <div className="flex-justify-end" style={{ marginTop: "20px" }}>
            </div>
        </>
    );
}

export default CreateIngredient;