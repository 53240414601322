import { useState, useEffect } from 'react';
import { useState as useHookState } from "@hookstate/core";
import { BrowserRouter, Route, Routes, Navigate, Link } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import axios from "axios";
// shared components
import CustomAlert from "./components/shared/CustomAlert";
import CustomConfirmation from "./components/shared/CustomConfirmation";
import CustomCircularProgress from './components/shared/CustomCircularProgress';
// modules
import Login from './components/modules/authentication/Login';
import Ingredients from './components/modules/ingredients/Ingredients';
import Products from './components/modules/products/Products';
import CreateProducts from "./components/modules/products/CreateProduct"
import Dashboard from "./components/modules/dashboard/dashboard"
import AuditTrail from "./components/modules/audit/AuditTrail"
import ProductContribution from "./components/modules/productContribution/ProductContribution"
import Payment from "./components/modules/payment/Payment"
import Privacy from "./components/modules/misc/PrivacyPolicy"
import CookiePolicy from './components/modules/misc/CookiePolicy';
// styles
import ThemeStyles from "./styles/ThemeStyles";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
// global states
import GlobalStates from './utils/GlobalStates';
// general components
import Aside from './components/general/Sidebar';
import Header from './components/general/Header';
import SidebarMobile from "./components/general/SidebarMobile"
// Third Party
import CookieConsent, { Cookies } from "react-cookie-consent";



function App(props) {
	const globalStates = useHookState(GlobalStates);

	const [theme, setTheme] = useState(ThemeStyles);

	let isContrancted = window.localStorage.getItem("isContrancted") === "true";
	const [collapsed, setCollapsed] = useState(isContrancted || false);
	const [opened, setOpened] = useState(window.innerWidth > 600 ? isContrancted || false : false);
	const [hideSidebar, setHideSidebar] = useState(true)

	useEffect(() => {

		if (window.localStorage.getItem("isSignedIn") === "true" && ![null, undefined, ""].includes(window.localStorage.getItem("userInfo"))) {
			globalStates.isLoggedIn.set(true);
			globalStates.isLoading.set(false)
		}
		else {
			window.localStorage.clear();
			globalStates.isLoading.set(false);
		}
	}, [JSON.stringify(globalStates.isLoading.get())]);

	useEffect(() => {
		if (globalStates.showFilterSidebar.get() && !collapsed) {
			handleCollapsedChange();
		}
	}, [JSON.stringify(globalStates.showFilterSidebar.get())]);

	useEffect(() => {
		if (window.location.pathname !== "/create-product/") {
			window.localStorage.setItem("productEditRow", null)
			window.localStorage.setItem("isEdit", false)
		}

	}, [window.location.pathname])


	// trigger to open or close the sidebar, trigger to 
	//setting open or not this is for submenu config this is need for condition below
	const handleCollapsedChange = () => {
		setCollapsed(!collapsed);
		setHideSidebar(!hideSidebar)
		//collapsed sidebar
		setOpened(!opened)
		window.localStorage.setItem('isContrancted', !collapsed);
		let elems = document.querySelectorAll(".react-slidedown");
		// getting all class name with react-slidedown 
		let bodyElems = document.getElementById("pagePannel"); // getting page element id for pages/panels
		if (opened === false) {
			// this condition is for submenu, once the value is false, we are removing the "closed" class from elems, so we can activate the hover when the sidebar is collapsed.
			[].forEach.call(elems, function (el) {
				//mapping all elements that has "react-slidedown" classname
				el.classList.remove("closed");
				// removing closed css from elems
			});
			// sidebar is closed
			bodyElems.classList.add("fullPanel");
			// adding fullPanel style to page panel css
			// bodyElems.classList.remove("normalPanel");
			// removing normalPanel style to page panel css
		}
		else {
			[].forEach.call(elems, function (el) {
				el.classList.add("closed");
				// adding closed css from elems
			});
			// sidebar is opened
			bodyElems.classList.remove("fullPanel");
			// removing fullpane style to page panel css
			// bodyElems.classList.add("normalPanel");
			// adding normalPanel style to page panel css
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter basename={props.baseUrl}>
				{globalStates.isLoading.get()
					? <CustomCircularProgress width={"100%"} height={"100%"} size={50} />
					: !globalStates.isLoggedIn.get()
						? <Routes>
							<Route path="/login" element={<Login />} />
							<Route path="/cookie-policy" element={<CookiePolicy />} />
							<Route path="/privacy-policy" element={<Privacy />} />
							<Route path="*" element={<Navigate to="/login" />} />
						</Routes>
						: <>
							{window.innerWidth > 600 ?
								<div className="app container-fluid">
									<div className="row">
										<div className={`col-2 p-0 ${opened ? "widthCollapsed" : ""}`}>
											<Aside
												opened={opened}
												collapsed={collapsed}
											/>
										</div>
										<div className={`col-10 p-0 ${opened ? "widthFull" : ""}`}>
											<Header
												collapsed={collapsed}
												handleCollapsedChange={handleCollapsedChange}
											/>
											<div style={{ width: globalStates.showFilterSidebar.get() ? "calc(100% - 300px)" : "100%", transition: "all ease-in-out .3s" }}>
												<div className="body-container" id="pagePannel">
													<Routes>
														{/* Add specific paths here */}
														<Route path="/dashboard" element={<Dashboard />} />
														<Route path="/products" element={<Products />} />
														<Route path="/create-product" element={<CreateProducts />} />
														<Route path="/ingredients" element={<Ingredients />} />
														<Route path="/audit-trail" element={<AuditTrail />} />
														<Route path="/product-contribution" element={<ProductContribution />} />
														<Route path="/payment" element={<Payment />} />
														{/* <Route path="/create-a-checkout" element={<Payment />} /> */}
														<Route path="/privacy-policy" element={<Privacy />} />
														<Route path="/cookie-policy" element={<CookiePolicy />} />
														<Route path="*" element={<Navigate to="/dashboard" />} />
													</Routes>
												</div>
											</div>
										</div>
									</div>
								</div> :

								<div className="app container-fluid">
									{hideSidebar === true ? null :
										<div className="row">
											<div className={`col-12 p-0`}>
												<SidebarMobile
													opened={opened}
													collapsed={collapsed}
												/>
											</div>
										</div>
									}
									<div className="row">
										<div className={`col-12 p-0 ${opened ? "widthFull" : ""}`}>
											<Header
												collapsed={collapsed}
												handleCollapsedChange={handleCollapsedChange}
											/>
											<div style={{ width: globalStates.showFilterSidebar.get() ? "calc(100% - 300px)" : "100%", transition: "all ease-in-out .3s" }}>
												<div className="body-container" id="pagePannel">
													<Routes>
														{/* Add specific paths here */}
														<Route path="/dashboard" element={<Dashboard />} />
														<Route path="/products" element={<Products />} />
														<Route path="/create-product" element={<CreateProducts />} />
														<Route path="/ingredients" element={<Ingredients />} />
														<Route path="/audit-trail" element={<AuditTrail />} />
														<Route path="/product-contribution" element={<ProductContribution />} />
														{/* <Route path="/payment" element={<Payment />} />
														<Route path="/create-a-checkout" element={<Payment />} /> */}
														<Route path="/privacy-policy" element={<Privacy />} />
														<Route path="/cookie-policy" element={<CookiePolicy />} />
														<Route path="*" element={<Navigate to="/dashboard" />} />
													</Routes>
												</div>
											</div>
										</div>
									</div>
								</div>
							}
						</>}
				{localStorage.getItem("CookiePolicy") !== "true" ?
					<CookieConsent
						location="bottom"
						buttonText="Yes, I agree"
						cookieName="myAwesomeCookieName2"
						style={{ background: "#2B373B" }}
						buttonStyle={{ color: "#F8F8F8", background: "#215481", fontSize: "15px" }}
						expires={150}
						onAccept={() => {
							localStorage.setItem("CookiePolicy", true)
						}}
					>
						<div>
							We use cookies to personalize your experience. By continuing to visit our website you agree to our use of cookies. <br />
							Please let us know if you agree to the use of all cookies.<br />
							<span style={{ fontSize: "13px" }}>
								To know more about our the policy, please refer to our <Link to="/cookie-policy">Cookie Policy Page.</Link>
							</span>
						</div>
					</CookieConsent>
					: null}
			</BrowserRouter>
			<CustomAlert />
			<CustomConfirmation />
		</ThemeProvider>
	);
}

export default App;
