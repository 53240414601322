import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import moment from "moment";
import { Grid } from "@mui/material";
import ConstantStyles from "../../../styles/ConstantStyles.json";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CustomButton from "../../shared/CustomButton";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import DateFilter from "../../shared/DateFilter"


export const options = {
    maintainAspectRatio: window.innerWidth > 600 ? true : false,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'History of Product',
        },
    },
};
function CreateIngredient(props) {

    const [historicalData, setHistoricalData] = useState([])
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [search, setSearch] = useState(false);


    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );


    useEffect(() => {

        let tempLabels = [""]
        let tempSelling = [0]
        let tempCM = [0]
        let tempCost = [0]
        let tempDate, temp;
        // console.log("IM BACK HEREEE", props.row.history)
        // console.log((startDate === null && endDate === null))
        props.row.history.map((key, i) => {
            if ((startDate === null && endDate === null)) {
                if (i === 0) {
                    setStartDate(new Date(key.modified).toLocaleDateString())
                }
                if (i === props.row.history.length - 1) {
                    setEndDate(new Date(key.modified).toLocaleDateString())
                }
            }
            else {
                // console.log("stra", startDate, endDate, new Date(key.modified).toLocaleDateString())
                tempDate = new Date(key.modified).toDateString();
                temp = new Date(key.modified).toLocaleDateString()
//                console.log(moment(startDate, "MM-DD-YYYY").isSameOrAfter(moment(temp, "MM-DD-YYYY")))

                if (moment(temp, "MM-DD-YYYY").isSameOrAfter(moment(startDate, "MM-DD-YYYY"))) {
                    tempSelling.push(key.selling_price)
                    tempCM.push(key.selling_price - key.cost)
                    tempCost.push(key.cost)
                    tempLabels.push(tempDate)
                    setSearch(true)
                }
            }

        })
        let dataset = {
            labels: tempLabels,
            datasets: [
                {
                    label: "Selling Price",
                    data: tempSelling,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)'
                },
                {
                    label: "Cost",
                    data: tempCost,
                    backgroundColor: 'rgba(53, 162, 235, 0.5)'
                },
                {
                    label: "Contribution Margin",
                    data: tempCM,
                    backgroundColor: 'rgba(255, 206, 86, 0.5)'
                }
            ]
        }
        setHistoricalData(dataset);


    }, [props.row.id, search]);

    return (
        <>
            <div
                style={{
                    width: window.innerWidth > 600 ? "60%" : "100%",
                    height: "calc(100vh - 190px)",
                    overflowY: "auto",
                    backgroundColor: ConstantStyles.white,
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    borderRadius: ConstantStyles["default-border-radius"],
                    marginTop: "5%",
                    marginLeft: window.innerWidth > 600 ? "25%" : "0%",
                    position: "relative"
                }}
            >
                <br />

                <Grid container spacing={2}>
                    <Grid item xs={11} sm={11} md={11} lg={11} className="text-header">
                        Historical Data
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <IconButton
                            aria-label="close"
                            onClick={() => props.handleClose()}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={8} sm={8} md={6} lg={5}>

                        {historicalData.length !== 0 ?
                            <DateFilter
                                startDate={startDate}
                                endDate={endDate}
                            /> : null}

                    </Grid>
                    <Grid item xs={4} sm={4} md={6} lg={7}>
                        <CustomButton
                            buttonLabel={"Search"}
                            onClick={() => { setSearch(true) }}
                        />
                    </Grid>
                </Grid>

                {historicalData.length !== 0 && search === true ?
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}
                                style={{
                                    backgroundColor: "white",
                                    height: window.innerWidth <= 600 ? "80vh" : "auto",
                                    width: window.innerWidth <= 600 ? "80vw" : "auto",
                                    position: "relative"
                                }}>
                                <Line options={options} data={historicalData} />
                            </Grid>
                        </Grid>
                        <br /> <br />
                        <b>Price Summary</b>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                Highest Selling Price: {props.row.aggregates.selling_price_high}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                Lowest Selling Price: {props.row.aggregates.selling_price_low}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                Average Selling Price: {props.row.aggregates.selling_price_avg}
                            </Grid>
                        </Grid>


                        <br /> <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                Highest Total Variable Cost: {props.row.aggregates.total_cost_high}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                Lowest Total Variable Cost: {props.row.aggregates.total_cost_low}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                Average Total Variable Cost: {props.row.aggregates.total_cost_avg}
                            </Grid>

                        </Grid>

                        <br /> <br />

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                Highest Contribution Margin: {props.row.aggregates.gross_profit_high}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                Lowest Contribution Margin: {props.row.aggregates.gross_profit_low}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                Average Contribution Margin: {props.row.aggregates.gross_profit_avg}
                            </Grid>

                        </Grid>



                    </div> : null}
                {/* {isSubmit && <CustomCircularProgress width={"100%"} height={"100%"} size={36} customStyle={{ position: "absolute", top: 0, left: 0, backgroundColor: "rgba(0, 0, 0, 0.2)" }} />} */}

            </div >

            <div className="flex-justify-end" style={{ marginTop: "20px" }}>
            </div>
        </>
    );
}

export default CreateIngredient;