export function formatNumberAbbreviation (value, decimal) {
    const numberWCommaFormat = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal
    });

    if (Math.abs(value) >= 1000000000000) {
        return numberWCommaFormat.format(value / 1000000000000) + 'T'; 
    }
    else if (Math.abs(value) >= 1000000000 && Math.abs(value) < 1000000000000) {
        return numberWCommaFormat.format(value / 1000000000) + 'B'; 
    }
    else if (Math.abs(value) >= 1000000 && Math.abs(value) < 1000000000) {
        return numberWCommaFormat.format(value / 1000000) + 'M'; 
    }
    else if (Math.abs(value) >= 1000 && Math.abs(value) < 1000000) {
        return numberWCommaFormat.format(value / 1000) + 'K'; 
    }
    else {
        return numberWCommaFormat.format(value);
    }
}

export function formatNumberAbbreviationWithoutK (value, decimal) {
    const numberWCommaFormat = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal
    });

    if (Math.abs(value) >= 1000000000000) {
        return numberWCommaFormat.format(value / 1000000000000) + 'T'; 
    }
    else if (Math.abs(value) >= 1000000000 && Math.abs(value) < 1000000000000) {
        return numberWCommaFormat.format(value / 1000000000) + 'B'; 
    }
    else if (Math.abs(value) >= 1000000 && Math.abs(value) < 1000000000) {
        return numberWCommaFormat.format(value / 1000000) + 'M'; 
    }
    else {
        return numberWCommaFormat.format(value);
    }
}

export function formatChartLabelNumberAbbreviation (value, decimal) {
    const numberWCommaFormat = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal
    });

    if (Math.abs(value) >= 1000000000000) {
        return parseFloat(numberWCommaFormat.format(value / 1000000000000)) + 'T'; 
    }
    else if (Math.abs(value) >= 1000000000 && Math.abs(value) < 1000000000000) {
        return parseFloat(numberWCommaFormat.format(value / 1000000000)) + 'B'; 
    }
    else if (Math.abs(value) >= 1000000 && Math.abs(value) < 1000000000) {
        return parseFloat(numberWCommaFormat.format(value / 1000000)) + 'M'; 
    }
    else if (Math.abs(value) >= 1000 && Math.abs(value) < 1000000) {
        return parseFloat(numberWCommaFormat.format(value / 1000)) + 'K'; 
    }
    else {
        return parseFloat(numberWCommaFormat.format(value));
    }
}

export function formatNumberWCommaRoundOff (value, decimal) {
    const numberWCommaFormat = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal
    });

    return numberWCommaFormat.format(value);
}

export function formatNumberWCommaTrunc (value, decimal) {
    value = (Math.trunc(value * Math.pow(10, decimal)) / Math.pow(10, decimal));

    const numberWCommaFormat = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal
    });

    return numberWCommaFormat.format(value);
}

export function formatNumberRoundOff (value, decimal) {
    value = (Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal));

    return value;
}

export function formatNumberRoundOffWithPercent (value, decimal) {
    value = (Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal));

    const numberWCommaFormat = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal
    });

    return numberWCommaFormat.format(value) + "%";
}

export function formatNumberRoundOffWithPeso (value, decimal) {
    value = (Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal));

    const numberWCommaFormat = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal,
        style: 'currency',
        currency: 'PHP'
    });

    return numberWCommaFormat.format(value).replace(/^(\D+)/, "$1 ");
}

export function convertHexToRGB (value) {
    if (value.includes("#")) {
        return value.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b).substring(1).match(/.{2}/g).map(x => parseInt(x, 16))
    }

    return value;
}

export function truncText (value, length) {
    if (value.length > length) {
        return value.substring(0, length) + "...";
    }

    return value;
}

export function isEmailValid (value) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
}

export function isTelephoneValid (value) {
    return value.length === 10;
}

export function isCellphoneValid (value) {
    return value.length === 11;
}