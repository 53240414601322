import PropTypes from 'prop-types';
import { CircularProgress } from "@mui/material";

function CustomCircularProgress(props) {

    return (
        <div className="flex-center" style={{ width: props.width, height: props.height, padding: props.padding, ...props.customStyle }}>
            <CircularProgress size={props.size} />
        </div>
    )
}

CustomCircularProgress.propTypes = {
    id: PropTypes.string,
    size: PropTypes.number,
    width: PropTypes.string,
    height: PropTypes.string,
    padding: PropTypes.string,
    customStyle: PropTypes.object
};

CustomCircularProgress.defaultProps = {
    id: "",
    size: 24,
    width: "auto",
    height: "auto",
    padding: "0px",
    customStyle: {}
};

export default CustomCircularProgress;