import { useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import {
    Alert,
    AlertTitle
} from "@mui/material";
import GlobalStates from '../../utils/GlobalStates';

function CustomAlert() {
	const globalStates = useHookState(GlobalStates);

    return (
        <div className={`alert-container ${globalStates.showAlert.get() ? "show-alert" : "hide-alert"}`}>
            <Alert severity={globalStates.alertType.get()}>
                <AlertTitle>{globalStates.alertTitle.get()}</AlertTitle>
                <p style={{ fontSize: 12 }}>{globalStates.alertBody.get()}</p>
            </Alert>
        </div>
    );
}

export default CustomAlert;