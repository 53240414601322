import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import moment from "moment";
import {
    Tooltip,
    IconButton,
    Modal,
    Box
} from "@mui/material";
import {
    Edit,
    Tune
} from "@mui/icons-material";
import CustomButton from "../../shared/CustomButton";
import CustomTable from "../../shared/CustomTable";
import CreateIngredient from "./CreateIngredient";
import GlobalStates from '../../../utils/GlobalStates';
import api from "../../../utils/Endpoints";


function Subscribers() {
    const globalStates = useHookState(GlobalStates);

    const [isTableLoading, setIsTableLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [isAddEditSubscriber, setIsAddEditSubscriber] = useState(false);
    const [selectedSubscriber, setSelectedSubscriber] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setIsEdit(false)
        setSelectedRow(null)
    };

    useEffect(() => {
        if (open === false)
            getSubscriberList(true);
    }, [open]);

    useEffect(() => {
        if (selectedSubscriber !== null && typeof selectedSubscriber !== "object") {
            setSelectedSubscriber(tableData.find(sub => sub.subId === selectedSubscriber) ?? null);
            setIsAddEditSubscriber(true);
        }
    }, [selectedSubscriber]);

    const setGlobalState = (alerType, alertTitle, alertBody, showAlert) => {
        globalStates.alertType.set(alerType);
        globalStates.alertTitle.set(alertTitle);
        globalStates.alertBody.set(alertBody);
        globalStates.showAlert.set(showAlert);

        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 3000);


    }

    const getSubscriberList = () => {
        setIsTableLoading(true);
        let counter = 10;
        let page = 2;
        let body = ""
        let ingredients = []
        api.ingredients.get()
            .then(response => {
                ingredients = response.data.results.map(ing => {
                    return {
                        ingredientName: ing.name,
                        purchasingPrice: ing.purchasing_price,
                        shippingCost: ing.shipping_cost,
                        UoM: ing.unit_of_measure,
                        action: ing.id,
                        rawData: ing
                    }
                })
                // while (response.data.count > counter) {
                //     api.ingredients.get(`/?page=${page}`)
                //         .then(res => {
                //             page = page + 1;
                //             counter = counter + 10;
                //             let ingre = []
                //             ingre = res.data.results.map(ing => {
                //                 return {
                //                     ingredientName: ing.name,
                //                     purchasingPrice: ing.purchasing_price,
                //                     shippingCost: ing.shipping_cost,
                //                     UoM: ing.unit_of_measure,
                //                     action: ing.id,
                //                     rawData: ing
                //                 }
                //             })
                //             ingredients = [...ingredients, ingre]
                //         })
                //         .error(err => {
                //             console.log(err)
                //         })
                // }
                // console.log("IM HERE")
                // if (counter > response.data.count)
                setTableData(ingredients)
            })
            .catch(error => {
                setGlobalState("error", "Error", "Failed to retrieve ingredients list.", true)

            })
            .finally(() => {
                setIsTableLoading(false);
            });

    }

    const getTableHeaders = useMemo(() => {
        return [
            { id: "ingredientName", name: "Ingredient Name", sortable: true },
            { id: "purchasingPrice", name: "Purchasing Price", sortable: true },
            { id: "shippingCost", name: "Shipping Cost", sortable: true },
            { id: "UoM", name: "Unit of Measure (UoM)", sortable: true },
            { id: "action", name: "Action", sortable: false, align: "center" },
        ];
    }, []);

    const handleEditClick = (row) => {
        setOpen(true)
        setSelectedRow(row)
        setIsEdit(true)
    }

    const handleDeleteClick = (id) => {
        api.ingredients.
            delete(id)
            .then(response => {
                setGlobalState("success", "Deleted Successfully!", "Ingredient deleted Successfully!", true)
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
            })

    }

    return (
        <>
            <div style={{ width: "100%" }}>
                <div className="flex-justify-between" style={{ alignItems: "flex-start" }}>
                    <p className="text-header">Ingredients</p>
                </div>
                <div className="flex-justify-between" style={{ alignItems: "flex-end", marginBottom: "10px" }}>
                    <p style={{ marginTop: "20px" }}><b>Total No. of Ingredients:</b> {tableData.length}</p>
                    <CustomButton
                        buttonLabel={"Add New Ingredient"}
                        onClick={handleOpen}
                    />
                </div>
                <CustomTable
                    headers={getTableHeaders}
                    data={tableData}
                    isLoading={isTableLoading}
                    noDataText={"No Ingredient Found"}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                    module={"Ingredients"}
                />
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <CreateIngredient
                    isEdit={isEdit}
                    name={isEdit === true ? selectedRow.ingredientName : ""}
                    purchasingPrice={isEdit === true ? parseFloat(selectedRow.purchasingPrice) : 0}
                    shippingCost={isEdit === true ? parseFloat(selectedRow.shippingCost) : 0}
                    UoM={isEdit === true ? selectedRow.UoM : 0}
                    id={isEdit === true ? selectedRow.action : ""}
                    handleClose={handleClose}
                    fromProductPage={false}
                />
            </Modal>
        </>
    )
}

export default Subscribers;